import { createContext, useContext } from 'react'

import { DEFAULT_EDITOR_TOOL } from 'config/constants'

import { Editor } from 'interfaces/interfaces'

// To prevent mis-reuse of this object, use this function to create a new object
export const INITIAL_SHAPE_STATE = () => ({
  cylinders: [],
  tori: [],
  planes: [],
  rhombi: [],
})
export const INITIAL_EXTRA_SHAPE_STATE = () => ({
  rhombi: [],
  planes: [],
})
const initialState: Editor = {
  addDistanceAnchor: () => null,
  autoDetectSituation: '',
  changeAutoDetectSituation: () => null,
  changeHoveredPoint: () => null,
  changeHoveredShapeId: () => null,
  changeIFCFiles: () => null,
  changeIsAllActionsDisabled: () => null,
  changeIsJobRunning: () => null,
  changeIsDragging: () => null,
  changeIsMouseDown: () => null,
  changeMaskRegions: () => null,
  changeMaskRegionsOutsideVisible: () => null,
  changeSelectedInspectionItem: () => null,
  changeSelectedSpacerInspectionItem: () => null,
  changeProcessingAnchor: () => null,
  changeSelectedIFCGeometryIndex: () => null,
  changeSelectedShapeIds: () => null,
  changeTool: () => null,
  changeSubTool: () => null,
  deleteDistances: () => null,
  deleteOverlaps: () => null,
  deleteShapes: () => null,
  deleteSpacerAnnotations: () => null,
  distanceAnchors: [],
  hoveredShapeId: '',
  IFCFiles: [],
  IFCGeometries: [],
  isDragging: false,
  isLayerModifying: false,
  isMouseDown: false,
  maskRegions: [],
  maskRegionsOutsideVisible: false,
  overlaps: [],
  selectedShapeIds: [],
  selectedTool: DEFAULT_EDITOR_TOOL,
  shapeGroups: [],
  shapes: INITIAL_SHAPE_STATE(),
  shapesDistances: { shapes: INITIAL_SHAPE_STATE(), distances: INITIAL_SHAPE_STATE(), situation: '' },
  shapesDistancesVisible: false,
  updateAllDistanceAnchorsStatus: () => null,
  updateAllOverlapsStatus: () => null,
  updateAllSelectedShapesStatus: () => null,
  updateAllShapesStatus: () => null,
  updateAllSpacerAnnotationsStatus: () => null,
  updateCanvasRenderers: () => null,
  updateDistanceAnchorPoint: () => null,
  updateDistanceAnchorStatus: () => null,
  updateOverlapStatus: () => null,
  updateSpacerAnchorStatus: () => null,
  updateSpacerAnnotationStatus: () => null,
  updateSelectedShapeIds: () => null,
  updateShapesDistancesVisibility: () => null,
  updateShapeGroups: () => null,
  updateShapeGroupStatus: () => null,
  updateShapeStatus: () => null,
  updateSpacerAnnotationAnchorPoint: () => null,
}

export const EditorContext = createContext(initialState)
export const useEditorContext = () => useContext(EditorContext)
