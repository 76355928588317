import { FC, useEffect, useState } from 'react'

import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { ChevronDownIcon } from 'assets/icons'

import { DIAMETERS, EDITOR_CUBOID_KEY } from 'config/constants'
import { INFO_PANEL_PADDING, INPUT_GROUP_STYLES } from 'config/styles'

import { findDiameterKeyByValue } from 'services/Util'

import { updateAnchorDiameter } from '../../store/anchor'
import { updateCuboidAnchorDiameter } from '../../store/cuboid'
import { setBaseDiameter } from '../../store/editor'

const DiameterPanel: FC<{ diameter?: number }> = ({ diameter }) => {
  const dispatch = useAppDispatch()
  const { cuboidAnchor } = useSelector((state: RootState) => state.cuboid)
  const { selectedAnchor } = useSelector((state: RootState) => state.anchor)
  const { baseDiameter } = useSelector((state: RootState) => state.editor)

  const [localValue, setLocalValue] = useState<number>()

  useEffect(() => {
    setLocalValue(diameter)
  }, [diameter])

  const updateValue = (newValue: number) => {
    if (!newValue || !selectedAnchor) {
      return
    }

    // update anchor states (diameter for selected anchor)
    if (selectedAnchor.shapeKey === EDITOR_CUBOID_KEY) {
      if (cuboidAnchor) {
        dispatch(updateCuboidAnchorDiameter(newValue))
      }
    } else {
      dispatch(updateAnchorDiameter(newValue))
    }

    // update base diameter state
    if (baseDiameter !== newValue) {
      dispatch(setBaseDiameter(newValue))
    }
  }

  return (
    <Box px={INFO_PANEL_PADDING}>
      <Menu variant="toolbar">
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          textAlign="left"
          fontWeight="normal"
          fontSize="xs"
          paddingX={2}
          height={7}
          width="100%"
          variant="toolbar"
          {...INPUT_GROUP_STYLES}
        >
          {localValue ? `${findDiameterKeyByValue(localValue)}（${localValue || ''}）` : ''}
        </MenuButton>
        <Portal>
          <MenuList>
            {Object.keys(DIAMETERS).map((d) => (
              <MenuItem
                key={d}
                backgroundColor={localValue === DIAMETERS[d] ? 'secondary.600' : 'transparent'}
                onClick={() => updateValue(DIAMETERS[d])}
                fontSize="xs"
              >
                {d}（{DIAMETERS[d]}）
              </MenuItem>
            ))}
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )
}

// Handle typing for props that is not required
DiameterPanel.defaultProps = {
  diameter: undefined,
}

export default DiameterPanel
