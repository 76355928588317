import { FC, useCallback, useContext, useState } from 'react'

import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { API_PROCESS_MAP, EDITOR_COLLAPSE_TYPES, EDITOR_SHAPE_KEYS, MAX_EDITOR_LAYERS } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { ShapeKey, TextsError } from 'interfaces/interfaces'

import { findDiameterKeyByValue, shapesWithKeyShouldShow, zeroPad } from 'services/Util'

import { anchorSelected, overrideAnchorProperties, overrideAnchorPropertiesWithShapeKey } from '../store/anchor'
import { shapeInDetectionSelected } from '../store/editor'
import CollapsePanel from './components/CollapsePanel'
import LayerItem from './components/LayerItem'
import LayerItemDivider from './components/LayerItemDivider'

const FramePanel: FC = () => {
  const { t } = useTranslation(['projects', 'error_message'])
  const dispatch = useAppDispatch()
  const { isLayerModifying } = useContext(EditorContext)
  const isExpanded = useSelector((state: RootState) =>
    state.editor.expandedPanels.includes(EDITOR_COLLAPSE_TYPES.detecting)
  )
  const { anchors, selectedAnchor } = useSelector((state: RootState) => state.anchor)

  const [cylinderShapesLayerCollapsed, setCylinderShapesLayerCollapsed] = useState(false)
  const [planeShapesLayerCollapsed, setPlaneShapesLayerCollapsed] = useState(false)
  const [toriShapesLayerCollapsed, setToriShapesLayerCollapsed] = useState(false)

  const updateAnchorStatus = useCallback(
    (props: Record<string, boolean>, index: number, shapeKey: ShapeKey) => {
      if (index < 0 || index >= anchors[shapeKey].length) {
        return
      }

      dispatch(overrideAnchorProperties({ shapeKey, anchorIndex: index, properties: props }))
    },
    [anchors, dispatch]
  )

  const updateAllAnchorsStatus = useCallback(
    (props: Record<string, boolean>, shapeKey: ShapeKey) => {
      dispatch(overrideAnchorPropertiesWithShapeKey({ shapeKey, properties: props }))
    },
    [dispatch]
  )

  const updateLayerVisibility = (invisible: boolean, index: number, shapeKey: ShapeKey) => {
    updateAnchorStatus({ invisible }, index, shapeKey)
  }
  const deleteLayer = (index: number, shapeKey: ShapeKey) => {
    updateAnchorStatus({ deleted: true }, index, shapeKey)
  }

  const zeroPlaces = MAX_EDITOR_LAYERS.toString().length
  const isSomeCylinderAnchorsVisible = anchors[EDITOR_SHAPE_KEYS.CYLINDERS].some((anchor) => !anchor.invisible)
  const isSomeTorusAnchorsVisible = anchors[EDITOR_SHAPE_KEYS.TORI].some((anchor) => !anchor.invisible)
  const isSomePlaneAnchorsVisible = anchors[EDITOR_SHAPE_KEYS.PLANES].some((anchor) => !anchor.invisible)
  const minHeight =
    40 +
    33 *
      ((shapesWithKeyShouldShow(anchors, EDITOR_SHAPE_KEYS.CYLINDERS) ? 1 : 0) +
        (shapesWithKeyShouldShow(anchors, EDITOR_SHAPE_KEYS.TORI) ? 1 : 0) +
        (shapesWithKeyShouldShow(anchors, EDITOR_SHAPE_KEYS.PLANES) ? 1 : 0))

  if (
    !shapesWithKeyShouldShow(anchors, EDITOR_SHAPE_KEYS.CYLINDERS) &&
    !shapesWithKeyShouldShow(anchors, EDITOR_SHAPE_KEYS.TORI) &&
    !shapesWithKeyShouldShow(anchors, EDITOR_SHAPE_KEYS.PLANES)
  ) {
    return null
  }

  const getLabel = (index: number, shapeKey: ShapeKey) =>
    // note that we put a whitespace between the text and the index
    `${
      shapeKey === EDITOR_SHAPE_KEYS.PLANES
        ? t('main_canvas.panels.shape.formwork', { ns: 'projects' })
        : t('main_canvas.panels.shape.rebar', { ns: 'projects' })
    } ${zeroPad(index + 1, zeroPlaces)}`

  // eslint-disable-next-line react/no-unstable-nested-components
  const RenderAnchorLayers = (
    shapeKey: ShapeKey,
    label: string,
    isSomeAnchorsVisible: boolean,
    collapsed: boolean,
    setExpansion: (expanded: boolean) => void
  ) => {
    if (!shapesWithKeyShouldShow(anchors, shapeKey)) {
      return null
    }

    const textMap: TextsError = t('error_apis', {
      returnObjects: true,
      processName: API_PROCESS_MAP.DETECT_SHAPES,
      ns: 'error_message',
    })

    return (
      <>
        <LayerItem
          collapsible
          collapsed={collapsed}
          updateExpansion={setExpansion}
          disabled={isLayerModifying}
          invisible={!isSomeAnchorsVisible}
          label={label}
          updateVisibility={(invisible) => updateAllAnchorsStatus({ invisible }, shapeKey)}
        />
        {!collapsed &&
          anchors[shapeKey].map(
            (anchor, anchorIndex) =>
              !anchor.deleted && (
                <LayerItem
                  disabled={isLayerModifying}
                  selected={selectedAnchor?.anchorIndex === anchorIndex}
                  deleteLayer={() => deleteLayer(anchorIndex, shapeKey)}
                  errorMessage={
                    anchor.error_id !== undefined
                      ? textMap.error_api.DETECT_SHAPES.messages?.[anchor.error_id]
                      : undefined
                  }
                  invisible={anchor.invisible}
                  key={getLabel(anchorIndex, shapeKey)}
                  label={
                    <HStack fontSize={10} alignItems="baseline" spacing={1}>
                      <Text>{getLabel(anchorIndex, shapeKey)}</Text>
                      {shapeKey !== EDITOR_SHAPE_KEYS.PLANES && (
                        <Text>{`(${findDiameterKeyByValue(anchor.diameter)})`}</Text>
                      )}
                    </HStack>
                  }
                  updateVisibility={(invisible) => updateLayerVisibility(invisible, anchorIndex, shapeKey)}
                  onClick={() => {
                    dispatch(
                      anchorSelected({
                        anchorObjectIndex: anchorIndex,
                        pointLength: anchor.points.length,
                        shapeKey,
                      })
                    )
                    dispatch(shapeInDetectionSelected())
                  }}
                  childLevel={1}
                />
              )
          )}
      </>
    )
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isExpanded ? minHeight : 0}
    >
      <CollapsePanel
        title={t('main_canvas.panels.shape.rebar_in_detection', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.detecting}
      >
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} divider={<LayerItemDivider />} overflowY="auto">
          {RenderAnchorLayers(
            EDITOR_SHAPE_KEYS.CYLINDERS,
            t('main_canvas.panels.shape.rebar', { ns: 'projects' }),
            isSomeCylinderAnchorsVisible,
            cylinderShapesLayerCollapsed,
            setCylinderShapesLayerCollapsed
          )}
          {RenderAnchorLayers(
            EDITOR_SHAPE_KEYS.TORI,
            t('main_canvas.panels.shape.hoop', { ns: 'projects' }),
            isSomeTorusAnchorsVisible,
            toriShapesLayerCollapsed,
            setToriShapesLayerCollapsed
          )}
          {RenderAnchorLayers(
            EDITOR_SHAPE_KEYS.PLANES,
            t('main_canvas.panels.shape.formwork', { ns: 'projects' }),
            isSomePlaneAnchorsVisible,
            planeShapesLayerCollapsed,
            setPlaneShapesLayerCollapsed
          )}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default FramePanel
