import { FC } from 'react'

import { Center, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const FullFeatureSuggestionText: FC = () => {
  const { t } = useTranslation('common')

  return (
    <Center position="sticky" bottom={0} bg="white" p={4}>
      <Text as="a" href={`mailto:${t('suggestions.full_feature.contact')}`}>
        {t('suggestions.full_feature.message')}: {t('suggestions.full_feature.contact')}
      </Text>
    </Center>
  )
}

export default FullFeatureSuggestionText
