import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { INITIAL_SHAPE_STATE } from 'contexts/Editor'

import { Anchors, CuboidKey, FocusedPoint, MeasureKey, PointArray, ShapeKey } from 'interfaces/interfaces'

// The frames for creating shapes: cylinder, torus, plane
// Other anchors are not included in this list
interface AnchorState {
  // TODO: putting maps (non-serializable object) in redux store is not recommended.
  // Find a way to avoid this.
  // ref: https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions

  anchors: Anchors
  selectedAnchor?: FocusedPoint
}

const initialState: AnchorState = {
  anchors: INITIAL_SHAPE_STATE(),
}

const anchorSlice = createSlice({
  name: 'anchor',
  initialState,
  reducers: {
    // setter function for anchors
    setAnchors: (state, action: PayloadAction<Anchors>) => ({
      ...state,
      anchors: action.payload,
    }),

    // setter function for selected point
    setSelectedAnchor: (state, action: PayloadAction<FocusedPoint | undefined>) => ({
      ...state,
      selectedAnchor: action.payload,
    }),

    // reducers which are more complicated than simple setters

    // update points of anchors with specified shape key/anchor index/point index
    updateAnchorPoint: (state, action: PayloadAction<{ pointInfo: FocusedPoint; point: PointArray }>) => {
      const {
        pointInfo: { shapeKey, anchorIndex, pointIndex },
        point,
      } = action.payload

      const updatedState = state
      updatedState.anchors[shapeKey as ShapeKey][anchorIndex].points[pointIndex] = point
      return updatedState
    },

    // update diameter of anchor with specified shape key/anchor index
    updateAnchorDiameter: (state, action: PayloadAction<number>) => {
      // early return for error case: make no updates if selectedAnchor is not set
      if (!state.selectedAnchor || !state.selectedAnchor.shapeKey || state.selectedAnchor.anchorIndex < 0) {
        return state
      }

      const { shapeKey, anchorIndex } = state.selectedAnchor
      const updatedState = state
      updatedState.anchors[shapeKey as ShapeKey][anchorIndex].diameter = action.payload
      return updatedState
    },

    // set diameter for all anchors with no diameter
    setDiameterForMissingAnchors: (state, action: PayloadAction<number>) => {
      const diameter = action.payload

      const updatedState = state
      updatedState.anchors.cylinders = updatedState.anchors.cylinders.map((anchor) => ({
        ...anchor,
        diameter: anchor.diameter || diameter,
      }))
      updatedState.anchors.tori = updatedState.anchors.tori.map((anchor) => ({
        ...anchor,
        diameter: anchor.diameter || diameter,
      }))

      return updatedState
    },

    // remove last anchor points of specified shape key
    removeLastAnchorPoints: (state, action: PayloadAction<ShapeKey>) => {
      const shapeKey = action.payload
      const updatedState = state
      updatedState.anchors[shapeKey].pop()
      return updatedState
    },

    // override properties of anchor with specified shape key/anchor index
    overrideAnchorProperties: (
      state,
      action: PayloadAction<{ shapeKey: ShapeKey; anchorIndex: number; properties: Record<string, boolean> }>
    ) => {
      const { shapeKey, anchorIndex, properties } = action.payload
      const updatedState = state
      updatedState.anchors[shapeKey][anchorIndex] = { ...updatedState.anchors[shapeKey][anchorIndex], ...properties }
      return updatedState
    },

    // override all anchor properties of specified shape key
    overrideAnchorPropertiesWithShapeKey: (
      state,
      action: PayloadAction<{ shapeKey: ShapeKey; properties: Record<string, boolean> }>
    ) => {
      const { shapeKey, properties } = action.payload
      const updatedState = state
      updatedState.anchors[shapeKey] = updatedState.anchors[shapeKey].map((anchor) => ({ ...anchor, ...properties }))
      return updatedState
    },

    // anchor object is selected
    anchorSelected: (
      state,
      action: PayloadAction<{
        anchorObjectIndex: number
        pointLength: number
        shapeKey: ShapeKey | MeasureKey | CuboidKey
      }>
    ) => {
      const { shapeKey, anchorObjectIndex, pointLength } = action.payload
      const { anchorIndex, pointIndex } = state.selectedAnchor
        ? (state.selectedAnchor as FocusedPoint)
        : { anchorIndex: -1, pointIndex: -1 }

      let newPointIndex = 0
      if (anchorObjectIndex === anchorIndex && pointLength > pointIndex + 1) {
        newPointIndex = pointIndex + 1
      }

      return {
        ...state,
        selectedAnchor: {
          anchorIndex: anchorObjectIndex,
          pointIndex: newPointIndex,
          shapeKey,
        },
      }
    },

    // reset functions
    resetSelectedAnchor: (state) => ({
      ...state,
      selectedAnchor: undefined,
    }),
    reset: () => initialState,
  },
})

export const {
  setAnchors,
  setSelectedAnchor,
  updateAnchorPoint,
  setDiameterForMissingAnchors,
  removeLastAnchorPoints,
  updateAnchorDiameter,
  overrideAnchorProperties,
  overrideAnchorPropertiesWithShapeKey,
  anchorSelected,
  resetSelectedAnchor,
  reset,
} = anchorSlice.actions

export default anchorSlice.reducer
