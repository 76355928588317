import { FC, useContext } from 'react'

import { User } from '@auth0/auth0-react'
import mixpanel from 'mixpanel-browser'
import CommentPopup from 'pages/projects/components/CommentPopup'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import useComment from 'hooks/Comment'

import { EDITOR_TOOLS } from 'config/constants'

import { Comment, CommentImage, CommentUploadedImage, ModalProps } from 'interfaces/interfaces'

import { commentClicked, resetCuboidAnchor, resetCuboidStates, setCuboid } from '../../store/cuboid'
import {
  reset as resetCommentStore,
  setIsMovingComment,
  setMovingCommentCartesianPosition,
} from '../../store/temporalComment'
import CommentIcon from './CommentIcon'
import UnplacedCommentIcons from './UnplacedCommentIcons'

const Comments: FC<{
  user: User | undefined
  project_id: string | undefined
  project_group_id: string | undefined
  selectedComment: Comment | undefined
  setSelectedComment: (comment: Comment | undefined) => void
  showModal: (props: ModalProps) => void
  handleError: (err: unknown, processName: string) => void
  // we can not read the props below with react hook (useCommentPopup) due to issue for JP texts typing:
  // see https://github.com/DataLabs-Japan/tekkin-app/pull/1561
  commentBody: string
  commentOpeningImageIds: string[]
  commentImageHandlingIndex: number
  commentDeletingImageIndexes: number[]
  commentEditingCaptions: string[]
  commentImages: CommentImage[]
  commentName: string
  commentUploadedImages: CommentUploadedImage[]
  setCommentBody: (body: string) => void
  setCommentDeletingImageIndexes: (indexes: number[]) => void
  setCommentEditingCaptions: (captions: string[]) => void
  setCommentImages: (images: CommentImage[]) => void
  setCommentName: (name: string) => void
  setCommentImageHandlingIndex: (index: number) => void
  setCommentUploadedImages: (images: CommentUploadedImage[]) => void
  toggleCommentOpeningImageId: (id: string) => void
}> = ({
  user,
  project_id,
  project_group_id,
  selectedComment,
  setSelectedComment,
  showModal,
  handleError,
  commentBody,
  commentOpeningImageIds,
  commentImageHandlingIndex,
  commentDeletingImageIndexes,
  commentEditingCaptions,
  commentImages,
  commentName,
  commentUploadedImages,
  setCommentBody,
  setCommentDeletingImageIndexes,
  setCommentEditingCaptions,
  setCommentImages,
  setCommentName,
  setCommentImageHandlingIndex,
  setCommentUploadedImages,
  toggleCommentOpeningImageId,
}) => {
  const { selectedTool, changeTool } = useContext(EditorContext)
  const { cuboid } = useSelector((state: RootState) => state.cuboid)
  const { movingCommentCartesianPosition, isMovingComment, commentPopupPosition } = useSelector(
    (state: RootState) => state.temporal_comment
  )

  const dispatch = useAppDispatch()

  const resetCommentStates = () => {
    dispatch(resetCommentStore())
    setSelectedComment(undefined)
    dispatch(resetCuboidStates())
  }

  const {
    comments,
    isLoading,
    fetchComments,
    openDeleteCommentConfirmModal,
    onConfirmMoveCartesianPosition,
    onConfirmMoveCuboid,
  } = useComment(project_id, selectedComment, setSelectedComment, resetCommentStates, showModal)

  const filteredComments = comments.filter(
    (comment) => comment.blueprint_position && !comment.cartesian_position && !comment.cuboid_position
  )
  const itemsPerRow = Math.ceil(Math.sqrt(filteredComments.length))
  const itemsPerCol = Math.ceil(filteredComments.length / itemsPerRow)
  const unplacedComments = filteredComments.map((comment, index) => {
    const unplacedIndex: [number, number] = [
      (index % itemsPerRow) - (itemsPerRow - 1) / 2,
      Math.floor(index / itemsPerRow) - (itemsPerCol - 1) / 2,
    ]
    return {
      ...comment,
      unplacedIndex,
    }
  })

  const onConfirmMove = async () => {
    if (movingCommentCartesianPosition) {
      await onConfirmMoveCartesianPosition(movingCommentCartesianPosition)
    } else if (cuboid) {
      await onConfirmMoveCuboid(cuboid)
    }

    resetCommentStates()
    return false
  }

  const commentPopupCartesianPosition = commentPopupPosition || selectedComment?.cartesian_position
  return (
    <>
      {comments.map((comment) =>
        comment.cartesian_position || comment.cuboid_position ? (
          <CommentIcon
            key={comment.thread_id}
            cartesianPosition={
              isMovingComment && comment.thread_id === selectedComment?.thread_id
                ? movingCommentCartesianPosition
                : comment.cartesian_position
            }
            cuboidPosition={
              isMovingComment && comment.thread_id === selectedComment?.thread_id
                ? cuboid?.center
                : comment.cuboid_position?.center
            }
            disabled={isLoading || (!!isMovingComment && selectedComment?.thread_id !== comment.thread_id)}
            visible={isMovingComment || selectedComment !== comment}
            onClick={() => {
              setSelectedComment(comment)
              dispatch(commentClicked(comment.cuboid_position))
            }}
            isMoving={!!isMovingComment && comment.thread_id === selectedComment?.thread_id}
            onCancelMove={resetCommentStates}
            onConfirmMove={onConfirmMove}
          />
        ) : null
      )}
      <UnplacedCommentIcons
        comments={unplacedComments}
        disabled={isLoading}
        selectedComment={selectedComment}
        setSelectedComment={setSelectedComment}
        isMovingComment={!!isMovingComment}
        onCancelMove={resetCommentStates}
        onConfirmMove={onConfirmMove}
      />
      {!isMovingComment &&
        (commentPopupCartesianPosition ||
          selectedComment?.cuboid_position ||
          selectedComment?.blueprint_position ||
          (selectedTool === EDITOR_TOOLS.COMMENT_CUBOID && cuboid)) && (
          <CommentPopup
            user={user}
            project_id={project_id}
            project_group_id={project_group_id}
            cartesianPosition={commentPopupCartesianPosition}
            cuboidPosition={selectedComment?.cuboid_position || cuboid}
            blueprintPosition={selectedComment?.blueprint_position}
            blueprintId={selectedComment?.blueprint_id}
            closePopup={() => {
              dispatch(resetCommentStore())
              setSelectedComment(undefined)
              dispatch(resetCuboidStates())
              setCommentImages([])
              setCommentEditingCaptions([])
              setCommentDeletingImageIndexes([])
              setCommentUploadedImages([])
            }}
            comment={selectedComment}
            onCommentModified={() => fetchComments()}
            deleteComment={() => {
              openDeleteCommentConfirmModal()

              // track with mixpanel
              mixpanel.track('Delete comment', {
                'Inspection area ID': project_id,
                'Thread ID': selectedComment?.thread_id,
                Situation: '3D GUI',
              })
            }}
            disabled={isLoading}
            commentBody={commentBody}
            commentImages={commentImages}
            commentName={commentName}
            deletingImageIndexes={commentDeletingImageIndexes}
            editingCaptions={commentEditingCaptions}
            updateCommentBody={setCommentBody}
            updateCommentImages={setCommentImages}
            updateCommentName={setCommentName}
            openingImageIds={commentOpeningImageIds}
            handlingImageIndex={commentImageHandlingIndex}
            uploadedImages={commentUploadedImages}
            toggleOpeningImageId={toggleCommentOpeningImageId}
            updateDeletingImageIndexes={setCommentDeletingImageIndexes}
            updateEditingCaptions={setCommentEditingCaptions}
            updateUploadedImages={setCommentUploadedImages}
            updateHandlingImageIndex={setCommentImageHandlingIndex}
            handleError={handleError}
            onMoveComment={() => {
              if (selectedComment?.cartesian_position) {
                dispatch(setMovingCommentCartesianPosition(selectedComment.cartesian_position))
                changeTool(EDITOR_TOOLS.COMMENT)
              } else if (selectedComment?.cuboid_position) {
                dispatch(setCuboid(selectedComment.cuboid_position))
                changeTool(EDITOR_TOOLS.COMMENT_CUBOID)
              } else {
                changeTool(EDITOR_TOOLS.COMMENT)
              }
              dispatch(resetCuboidAnchor())
              dispatch(setIsMovingComment(true))
            }}
            forEditor
          />
        )}
      {selectedComment &&
        unplacedComments.some((comment) => comment.thread_id === selectedComment.thread_id) &&
        isMovingComment && (
          <CommentIcon
            cartesianPosition={selectedTool === EDITOR_TOOLS.COMMENT ? movingCommentCartesianPosition : undefined}
            cuboidPosition={selectedTool === EDITOR_TOOLS.COMMENT_CUBOID ? cuboid?.center : undefined}
            disabled={isLoading}
            visible
            isMoving
            onCancelMove={resetCommentStates}
            onConfirmMove={onConfirmMove}
          />
        )}
    </>
  )
}

export default Comments
