import { FC, useContext } from 'react'

import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import {
  EDITOR_COLLAPSE_TYPES,
  EDITOR_MEASURE_KEYS,
  EDITOR_SHAPE_TEMP_ID_PREFIX,
  MAX_EDITOR_LAYERS,
} from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { zeroPad } from 'services/Util'

import { anchorSelected } from '../store/anchor'
import { overlapSelected } from '../store/editor'
import CollapsePanel from './components/CollapsePanel'
import LayerItem from './components/LayerItem'

const OverlapPanel: FC = () => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const { overlaps, isLayerModifying, updateOverlapStatus, updateAllOverlapsStatus, deleteOverlaps } =
    useContext(EditorContext)

  const isExpanded = useSelector((state: RootState) =>
    state.editor.expandedPanels.includes(EDITOR_COLLAPSE_TYPES.overlap)
  )
  const { selectedAnchor } = useSelector((state: RootState) => state.anchor)

  const { project_id } = useParams<{ project_id: string }>()

  const updateLayerVisibility = (invisible: boolean, index: number) => {
    updateOverlapStatus({ invisible }, index)

    // track with mixpanel
    mixpanel.track('Change visibility of overlap', {
      'Inspection area ID': project_id,
      Granuarity: 'individual',
      'Visibility (new)': !invisible,
      'Visibility (old)': invisible,
      'Overlap number': 1,
    })
  }

  const zeroPlaces = MAX_EDITOR_LAYERS.toString().length
  const isSomeOverlapsVisible = overlaps.some((overlap) => !overlap.invisible)

  if (!overlaps?.length) {
    return null
  }

  const getLabel = (index: number, isTemporary: boolean) => {
    const prefix = isTemporary ? '! ' : t('main_canvas.panels.overlap_length.overlap_length', { ns: 'projects' })
    // note that we put a whitespace between prefix and the index
    return `${prefix} ${zeroPad(index + 1, zeroPlaces)}`
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isExpanded ? 20 : 10}
      data-testid="overlap-panel"
    >
      <CollapsePanel
        title={t('main_canvas.panels.overlap_length.title', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.overlap}
      >
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          <LayerItem
            disabled={isLayerModifying}
            invisible={!isSomeOverlapsVisible}
            label={`${t('main_canvas.panels.overlap_length.overlap_length', { ns: 'projects' })} (${overlaps.length})`}
            updateVisibility={(invisible) => updateAllOverlapsStatus({ invisible })}
          />
          {overlaps.map(
            (overlap, overlapIndex) =>
              !overlap.deleted && (
                <LayerItem
                  isHighlighting={overlap.isHighlighting}
                  disabled={isLayerModifying}
                  selected={selectedAnchor?.anchorIndex === overlapIndex}
                  deleteLayer={() => deleteOverlaps(overlapIndex)}
                  invisible={overlap.invisible}
                  key={getLabel(overlapIndex, !!overlap.overlap_length_id?.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX))}
                  label={
                    <HStack fontSize={10} alignItems="baseline" spacing={1}>
                      <Text>
                        {getLabel(overlapIndex, !!overlap.overlap_length_id?.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX))}
                      </Text>
                    </HStack>
                  }
                  updateVisibility={(invisible) => updateLayerVisibility(invisible, overlapIndex)}
                  onClick={() => {
                    dispatch(
                      anchorSelected({
                        anchorObjectIndex: overlapIndex,
                        pointLength: overlap.positions_for_distance.length,
                        shapeKey: EDITOR_MEASURE_KEYS.OVERLAP,
                      })
                    )
                    dispatch(overlapSelected())
                  }}
                  childLevel={1}
                />
              )
          )}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default OverlapPanel
