import { FC, useContext, useEffect, useRef, useState } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import InviteNonPayingUserModal from 'components/Navbar/InviteUserModal'
import mixpanel from 'mixpanel-browser'
import { Trans, useTranslation } from 'react-i18next'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'
import { TOAST_CONFIG } from 'config/styles'

import { ErrorResponse, ProjectGroup, TextsError } from 'interfaces/interfaces'

import { extractStatusId, generateErrorMessage } from 'services/ErrorHandler'
import { inviteUserToProjectGroup } from 'services/ProjectGroups'
import { isValidEmail } from 'services/Validation'

const InviteUserModal: FC<{
  isOpen: boolean
  invitingProjectGroup?: ProjectGroup
  onConfirm: () => void
}> = ({ isOpen, invitingProjectGroup, onConfirm }) => {
  const { t } = useTranslation(['dashboard', 'global_modal'])
  const inputRef = useRef<HTMLInputElement>(null)

  const { showModal, handleError } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)
  const { getProjectGroups } = useContext(ProjectsContext)

  const toast = useToast()

  const [emailAddress, setEmailAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

  // reset email address when modal is opened
  useEffect(() => {
    if (isOpen) {
      setEmailAddress('')
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }, [isOpen])

  const isValidForm = () => !isLoading && isValidEmail(emailAddress)

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && isValidForm()) {
      void onInviteUser()
    }
  }

  const onInviteUser = async () => {
    if (!invitingProjectGroup) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      onConfirm()
      setIsLoading(false)
      return false
    }

    const invitedProjectGroup = await inviteUserToProjectGroup(
      token,
      invitingProjectGroup.project_group_id,
      emailAddress,
      // override error handler
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (err: any, processName: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const statusId = extractStatusId(err.response.data as ErrorResponse)
        if (statusId === 40402) {
          const textMap: TextsError = t('error_apis', {
            returnObjects: true,
            processName,
            ns: 'error_message',
          })
          const errorMessage = generateErrorMessage(err, processName, textMap)

          showModal({
            confirmText: t('ok', { ns: 'global_modal' }),
            title: t('title_error', { ns: 'global_modal' }),
            body: errorMessage,
            resolveText: t('components.invite_user_modal.send_invitation_to_create_account', { ns: 'dashboard' }),
            modalType: MODAL_TYPES.ERROR,
            onResolve: () => {
              setIsInviteModalOpen(true)
            },
          })
        } else {
          handleError(err, processName)
        }
      }
    )
    if (invitedProjectGroup) {
      toast({
        ...TOAST_CONFIG,
        title: t('components.invite_user_modal.invite_success_message', { ns: 'dashboard' }),
      })
      await getProjectGroups()
    }

    setIsLoading(false)
    onConfirm()

    // track with mixpanel
    mixpanel.track('Invite user to project', {
      'Project ID': invitingProjectGroup.project_group_id,
      'Project Name': invitingProjectGroup.project_group_name,
      'Target email': emailAddress,
    })

    return true
  }

  return (
    <>
      <Modal closeOnOverlayClick={!isLoading} isOpen={isOpen} onClose={onConfirm} trapFocus={false} size="md">
        <ModalOverlay />
        <ModalContent onKeyDown={handleKeyDown}>
          <ModalHeader>{t('components.invite_user_modal.invite_user', { ns: 'dashboard' })}</ModalHeader>
          <ModalCloseButton hidden={isLoading} />
          <ModalBody position="relative">
            <FormControl>
              <FormLabel>
                <Trans
                  i18nKey="components.invite_user_modal.invite_to_project"
                  ns="dashboard"
                  values={{ project_group_name: invitingProjectGroup?.project_group_name }}
                  components={{ bold: <Text fontWeight="bold" display="inline-block" /> }}
                />
              </FormLabel>
              <Input
                ref={inputRef}
                placeholder={t('components.invite_user_modal.form_input_placeholder', { ns: 'dashboard' })}
                id="email_address"
                type="text"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value.trim())}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter mt={8} justifyContent="center">
            <Button me={3} py={2} minW="100px" onClick={() => onConfirm()} disabled={isLoading}>
              {t('components.invite_user_modal.cancel', { ns: 'dashboard' })}
            </Button>
            <Button
              disabled={!isValidForm()}
              colorScheme="primary"
              me={3}
              py={2}
              minW="100px"
              onClick={() => onInviteUser()}
              isLoading={isLoading}
              loadingText={t('components.invite_user_modal.inviting', { ns: 'dashboard' })}
              spinnerPlacement="end"
            >
              {t('components.invite_user_modal.invite', { ns: 'dashboard' })}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <InviteNonPayingUserModal
        isOpen={isInviteModalOpen}
        onConfirm={() => {
          onConfirm()
          setIsInviteModalOpen(false)
        }}
        email={emailAddress}
      />
    </>
  )
}

export default InviteUserModal
