import { FC } from 'react'

import { Center } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { Vector3 } from 'three'

import { AnchorPointIcon } from 'assets/icons'

import { Z_INDEX_RANGE } from 'config/constants'

/**
 * Component for a point icon without any interaction
 */
const StaticPointIcon: FC<{
  position: Vector3
  color: string
}> = ({ position, color }) => (
  <Html
    position={position}
    style={{
      transform: 'translate(-50%, -50%)',
      width: '20px',
      height: '20px',
      position: 'relative',
    }}
    zIndexRange={Z_INDEX_RANGE.anchor_point}
    className="unprintable"
  >
    <Center>
      <AnchorPointIcon className={color} />
    </Center>
  </Html>
)

export default StaticPointIcon
