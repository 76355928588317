import { FC, useContext } from 'react'

import { Box, Spacer } from '@chakra-ui/react'
import CollapsePanel from 'pages/projects/editor/infoPanels/components/CollapsePanel'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import {
  EDITOR_COLLAPSE_TYPES,
  EDITOR_CUBOID_KEY,
  EDITOR_MEASURE_KEYS,
  EDITOR_SHAPE_KEYS,
  EDITOR_TOOLS,
} from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import DiameterPanel from './DiameterPanel'

const PropertyPanel: FC = () => {
  const { t } = useTranslation(['projects'])
  const { selectedTool } = useContext(EditorContext)
  const { baseDiameter } = useSelector((state: RootState) => state.editor)
  const { anchors, selectedAnchor } = useSelector((state: RootState) => state.anchor)
  const { cuboidAnchor } = useSelector((state: RootState) => state.cuboid)

  if (
    ![EDITOR_TOOLS.CYLINDER, EDITOR_TOOLS.CYLINDER_CUBOID, EDITOR_TOOLS.TORUS, EDITOR_TOOLS.TORUS_CUBOID].includes(
      selectedTool
    ) ||
    !selectedAnchor ||
    selectedAnchor.shapeKey === EDITOR_SHAPE_KEYS.PLANES ||
    selectedAnchor.shapeKey === EDITOR_MEASURE_KEYS.SPACER_ANNOTATION ||
    selectedAnchor.shapeKey === EDITOR_MEASURE_KEYS.DISTANCE ||
    selectedAnchor.shapeKey === EDITOR_MEASURE_KEYS.OVERLAP
  ) {
    return null
  }

  const getDiameterValue = () => {
    if (selectedAnchor.shapeKey === EDITOR_CUBOID_KEY) {
      return cuboidAnchor?.diameter || baseDiameter
    }
    if (selectedAnchor.shapeKey === EDITOR_SHAPE_KEYS.CYLINDERS || selectedAnchor.shapeKey === EDITOR_SHAPE_KEYS.TORI) {
      return anchors[selectedAnchor.shapeKey][selectedAnchor.anchorIndex].diameter
    }
    return baseDiameter
  }

  return (
    <Box backgroundColor="gray.800" borderBottomLeftRadius="md" borderTopLeftRadius="md" w="100%">
      <CollapsePanel
        title={t('main_canvas.panels.specify_diameter', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.diameter}
      >
        <Spacer h={INFO_PANEL_PADDING * 1.5} />

        <DiameterPanel diameter={getDiameterValue()} />
        <Spacer h={INFO_PANEL_PADDING} />
      </CollapsePanel>
    </Box>
  )
}

export default PropertyPanel
