import { FC, useContext, useEffect, useRef, useState } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isMobile } from 'react-device-detect'
import { useParams } from 'react-router-dom'

import { FolderIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { uploadFile } from 'services/AWS'
import { getSignedUrlForUploadXMLFile, importXMLFile } from 'services/InspectionSheet'

const XMLImportModal: FC<{
  isOpen: boolean
  inspectionSheetId: string
  onConfirm: (result?: boolean) => void
}> = ({ isOpen, inspectionSheetId, onConfirm }) => {
  const { project_id } = useParams<{ project_id: string }>()
  const { getAccessToken } = useContext(UserContext)
  const { showModal, handleError } = useContext(GlobalModalContext)

  const uploadFileRef = useRef<HTMLInputElement>(null)

  const [file_name, setFileName] = useState('')
  const [upload_file, setUploadFile] = useState<File | null>(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  // reset states when modal is opened
  useEffect(() => {
    if (isOpen) {
      setFileName('')
      setUploadFile(null)
      setUploadPercent(0)
      setIsLoading(false)
    }
  }, [isOpen])

  const isValidForm = () => !isLoading && !!upload_file

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && isValidForm()) {
      void onUploadXMLFile()
    }
  }

  const resetCreatingStates = () => {
    setUploadPercent(0)
    setIsLoading(false)
  }

  const handleFileClick = () => {
    uploadFileRef.current?.click()
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e || !e.target || !e.target.files || !e.target.files.length) return
    const file = e.target.files[0]
    setUploadFile(file)
    setFileName(file.name)
  }

  const onUploadXMLFile = () => {
    if (!project_id || !upload_file) {
      return false
    }

    return showModal({
      body: 'XMLファイルをアップロードすると、現在の帳票データが上書きされます。',
      confirmText: '確定',
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          setIsLoading(true)
          const token = await getAccessToken()
          if (!token) {
            setIsLoading(false)
            return false
          }

          const signedResult = await getSignedUrlForUploadXMLFile(token, project_id, file_name, handleError)
          if (!signedResult) {
            setIsLoading(false)
            return false
          }

          // Upload file
          const uploadResult = await uploadFile(
            signedResult.url,
            signedResult.content_type,
            upload_file,
            setUploadPercent,
            handleError
          )

          if (!uploadResult) {
            resetCreatingStates()
            return false
          }

          if (
            !(await importXMLFile(
              token,
              project_id,
              inspectionSheetId,
              signedResult.external_sheet_id,
              signedResult.s3_filename,
              signedResult.original_filename,
              handleError
            ))
          ) {
            setIsLoading(false)
            return false
          }

          // Reset states
          setIsLoading(false)
          setUploadFile(null)
          resetCreatingStates()
          if (uploadFileRef && uploadFileRef.current) uploadFileRef.current.value = ''

          // track with mixpanel
          mixpanel.track('Import xml file', {
            'Inspection area ID': project_id,
          })

          return onConfirm(true)
        })()
        return true
      },
      title: '確認',
    })
  }

  return (
    <Modal closeOnOverlayClick={!isLoading} isOpen={isOpen} onClose={onConfirm} trapFocus={false}>
      <ModalOverlay />
      <ModalContent onKeyDown={handleKeyDown}>
        <ModalHeader>XMLファイルをアップロード</ModalHeader>
        <ModalCloseButton hidden={isLoading} />
        <ModalBody>
          <FormControl mt={4}>
            <FormLabel htmlFor="file">xml ファイル選択</FormLabel>
            <InputGroup size="md">
              <Input
                data-testid="file-upload"
                hidden
                ref={uploadFileRef}
                type="file"
                accept={isMobile ? '' : '.xml'}
                onChange={handleFileChange}
              />
              <Input isReadOnly value={file_name} />
              <InputRightElement w={14}>
                <IconButton
                  aria-label="Upload file"
                  borderColor="inherit"
                  borderWidth="1px"
                  className="upload-file-button"
                  disabled={isLoading}
                  fontSize="lg"
                  icon={<FolderIcon />}
                  onClick={handleFileClick}
                  w="100%"
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </ModalBody>

        <ModalFooter mt={8}>
          <Button disabled={isLoading} me={3} py={2} minW="100px" onClick={() => onConfirm()}>
            キャンセル
          </Button>

          <Button
            colorScheme="primary"
            disabled={!isValidForm()}
            isLoading={isLoading}
            loadingText={`アップロード中...${uploadPercent}%`}
            minW="100px"
            onClick={onUploadXMLFile}
            py={2}
          >
            アップロード
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default XMLImportModal
