import { FC, useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  VStack,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ChevronDownIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { DIAMETERS } from 'config/constants'

import { InspectionItem, InspectionItemNumberValues, InspectionShapeIds, ShapesDistances } from 'interfaces/interfaces'

import { scaleDownValues, scaleUpValues, upsertInspectionItem } from 'services/InspectionSheet'
import { findDiameterKeyByValue, meterToMillimeter } from 'services/Util'

const DEFAULT_NUMBER_VALUES = {
  specified_value: null,
  estimated_value: null,
}
const ValuesModal: FC<{
  inspectionSheetId: string
  valuesUpdatingInspectionItem?: InspectionItem
  situation: string
  evaluatedTekkinNumber: number
  evaluatedDiameter?: number // meter
  evaluatedMeanDistance?: number // meter
  evaluatedCoverDistance?: number // meter
  evaluatedShapesDistances?: ShapesDistances
  evaluatedPermutationMap?: number[]
  shapeIds?: InspectionShapeIds
  isOpen: boolean
  onConfirm: (result?: boolean) => void
  onUpdateShapes: () => void
}> = ({
  inspectionSheetId,
  valuesUpdatingInspectionItem,
  evaluatedTekkinNumber,
  evaluatedDiameter,
  evaluatedMeanDistance,
  evaluatedCoverDistance,
  evaluatedShapesDistances,
  evaluatedPermutationMap,
  shapeIds,
  situation,
  isOpen,
  onConfirm,
  onUpdateShapes,
}) => {
  const { t } = useTranslation(['projects'])

  const { project_id } = useParams<{ project_id: string }>()
  const { handleError } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)
  const { selectedInspectionItem, project } = useContext(EditorContext)

  const [isLoading, setIsLoading] = useState(false)

  // states for inspection item
  const [inspectionItem, setInspectionItem] = useState<InspectionItem>()
  const [partName, setPartName] = useState<string>('')
  const [diameter, setDiameter] = useState<InspectionItemNumberValues>(DEFAULT_NUMBER_VALUES) // millimeter
  const [number, setNumber] = useState<InspectionItemNumberValues>(DEFAULT_NUMBER_VALUES)
  const [numberString, setNumberString] = useState('')
  const [meanDistance, setMeanDistance] = useState<InspectionItemNumberValues>(DEFAULT_NUMBER_VALUES) // millimeter
  const [meanDistanceString, setMeanDistanceString] = useState('')
  const [coverDistance, setCoverDistance] = useState<InspectionItemNumberValues>(DEFAULT_NUMBER_VALUES) // millimeter
  const [coverDistanceSpecifiedString, setCoverDistanceSpecifiedString] = useState('')
  const [coverDistanceEstimatedString, setCoverDistanceEstimatedString] = useState('')

  // update states for inspection item
  useEffect(() => {
    if (isOpen) {
      setInspectionItem(valuesUpdatingInspectionItem || (selectedInspectionItem as InspectionItem))
    }
  }, [valuesUpdatingInspectionItem, selectedInspectionItem, isOpen])

  // update each spec/actual value for inspection item
  useEffect(() => {
    // do nothing if modal is closed
    if (!isOpen) return

    setPartName(inspectionItem?.part_name || '')

    setDiameter(
      scaleUpValues(
        inspectionItem?.diameter,
        null,
        evaluatedDiameter !== undefined ? meterToMillimeter(evaluatedDiameter) : null
      )
    )

    const numberSpecifiedValue = inspectionItem?.number.specified_value ?? null
    setNumber({
      specified_value: numberSpecifiedValue,
      // Use item value when updating item. Use evaluated value when updating shapes
      estimated_value: valuesUpdatingInspectionItem?.number.estimated_value || evaluatedTekkinNumber,
    })
    setNumberString(numberSpecifiedValue?.toString() || '')

    const meanDistanceScaledValues = scaleUpValues(
      // Use item value when updating item. Use evaluated value when updating shapes
      valuesUpdatingInspectionItem?.mean_distance,
      inspectionItem?.mean_distance.specified_value
        ? meterToMillimeter(inspectionItem.mean_distance.specified_value)
        : null,
      evaluatedMeanDistance !== undefined ? meterToMillimeter(evaluatedMeanDistance) : null
    )
    setMeanDistance(meanDistanceScaledValues)
    setMeanDistanceString(meanDistanceScaledValues?.specified_value?.toString() || '')

    const coverDistanceEstimate =
      (evaluatedCoverDistance || inspectionItem?.cover_distance.estimated_value) ?? undefined
    const coverDistanceScaledValues = scaleUpValues(
      // Use item value when updating item. Use evaluated value when updating shapes
      valuesUpdatingInspectionItem?.cover_distance,
      inspectionItem?.cover_distance.specified_value
        ? meterToMillimeter(inspectionItem.cover_distance.specified_value)
        : null,
      coverDistanceEstimate !== undefined ? meterToMillimeter(coverDistanceEstimate) : null
    )
    setCoverDistance(coverDistanceScaledValues)
    setCoverDistanceSpecifiedString(coverDistanceScaledValues?.specified_value?.toString() || '')
    setCoverDistanceEstimatedString(coverDistanceScaledValues?.estimated_value?.toString() || '')
  }, [
    valuesUpdatingInspectionItem,
    selectedInspectionItem,
    evaluatedCoverDistance,
    evaluatedMeanDistance,
    evaluatedDiameter,
    evaluatedTekkinNumber,
    inspectionItem,
    isOpen,
  ])

  const resetStates = () => {
    setInspectionItem(undefined)
    setPartName('')
    setDiameter(DEFAULT_NUMBER_VALUES)
    setNumber(DEFAULT_NUMBER_VALUES)
    setNumberString('')
    setMeanDistance(DEFAULT_NUMBER_VALUES)
    setMeanDistanceString('')
    setCoverDistance(DEFAULT_NUMBER_VALUES)
    setCoverDistanceSpecifiedString('')
    setCoverDistanceEstimatedString('')
  }

  // reset states when modal is closed
  useEffect(() => {
    setIsLoading(false)

    if (!isOpen) {
      resetStates()
    }
  }, [isOpen])

  const confirmValues = async () => {
    if (!project_id) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    // Use item value when updating item. Use evaluated value when updating shapes
    const updatedShapeIds = shapeIds ||
      valuesUpdatingInspectionItem?.shape_ids || {
        cylinders: [],
        tori: [],
        planes: [],
      }
    const updatedSituation = valuesUpdatingInspectionItem?.situation || situation || ''

    const newItem = {
      inspection_item_id: inspectionItem?.inspection_item_id,
      part_name: partName,
      situation: updatedSituation,
      diameter: scaleDownValues(diameter),
      mean_distance: scaleDownValues(meanDistance),
      cover_distance: scaleDownValues(coverDistance),
      number,
      shape_ids: updatedShapeIds,
      // As user has acknowledged this action, there is no need to show need-update alert
      need_update: false,
      // Use item value when updating item. Use evaluated value when updating shapes
      aligned_shapes_individual_distance:
        (evaluatedShapesDistances?.cylinders.length && evaluatedShapesDistances.cylinders) ||
        (evaluatedShapesDistances?.tori.length && evaluatedShapesDistances.tori) ||
        valuesUpdatingInspectionItem?.aligned_shapes_individual_distance ||
        null,
      // Use item value when updating item. Use evaluated value when updating shapes
      cylinders_and_plane_individual_distance:
        (evaluatedShapesDistances?.planes.length && evaluatedShapesDistances.planes) ||
        valuesUpdatingInspectionItem?.cylinders_and_plane_individual_distance ||
        null,
      // Use item value when updating item. Use evaluated value when updating shapes
      permutation_map: (evaluatedPermutationMap || valuesUpdatingInspectionItem?.permutation_map) ?? null,
    }

    const upsertResult = await upsertInspectionItem(token, project_id, inspectionSheetId, newItem, handleError)
    if (upsertResult) {
      onConfirm(true)
      // track with mixpanel
      let track_title = ''
      if (!newItem.inspection_item_id) {
        if (newItem.situation === '') {
          track_title = 'Create inspection item - without shapes'
        } else {
          track_title = 'Create inspection item - with shapes'
        }
      } else {
        track_title = 'Update inspection item'
      }
      mixpanel.track(track_title, {
        'Inspection area ID': project_id,
        Situation: newItem.situation,
        Diameter: scaleDownValues(diameter),
        Number: number,
        Mean_distance: scaleDownValues(meanDistance),
        Cover_distance: scaleDownValues(coverDistance),
      })

      return true
    }

    return false
  }

  const renderValueInputs = (
    values: InspectionItemNumberValues | undefined,
    regionTitle: string,
    specifiedLabel: string,
    estimatedLabel: string,
    unit: string,
    setter: (newValues: InspectionItemNumberValues) => void,
    stringValue?: string,
    stringSetter?: (newValue: string) => void,
    forDiameter?: boolean,
    forCoverDistance?: boolean
  ) => (
    <VStack height="100%" spacing={1} paddingTop={6}>
      <HStack width="100%" alignItems="baseline">
        <Text fontWeight="bold" fontSize="lg" whiteSpace="nowrap">
          {regionTitle}
        </Text>
        <Divider />
      </HStack>
      <HStack justifyContent="flex-start" width="100%" paddingLeft={5} paddingTop={2} spacing={8}>
        <FormControl flex={1}>
          <FormLabel htmlFor={regionTitle}>
            {specifiedLabel}
            {unit.length ? `（${unit}）` : ``}
          </FormLabel>
          {!!forDiameter && (
            <Menu autoSelect={false}>
              <MenuButton
                id={regionTitle}
                as={Button}
                rightIcon={<ChevronDownIcon />}
                backgroundColor="transparent"
                borderColor="secondary.200"
                borderWidth={1}
                minWidth={148}
                textAlign="left"
                fontWeight="normal"
                fontSize="md"
                paddingRight={2}
              >
                {values?.specified_value
                  ? `${findDiameterKeyByValue(values?.specified_value)}（${values?.specified_value || ''}）`
                  : ''}
              </MenuButton>
              <MenuList>
                {Object.keys(DIAMETERS).map((d) => (
                  <MenuItem
                    key={d}
                    backgroundColor={values?.specified_value === DIAMETERS[d] ? 'secondary.50' : 'transparent'}
                    onClick={() =>
                      setter({
                        specified_value: DIAMETERS[d],
                        estimated_value: values?.estimated_value ?? null,
                      })
                    }
                  >
                    {d}（{DIAMETERS[d]}）
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}
          {!forDiameter && (
            <NumberInput
              min={0}
              max={9999}
              value={stringValue || ''}
              onChange={(valueAsString, valueAsNumber) => {
                setter({
                  specified_value: valueAsNumber,
                  estimated_value: values?.estimated_value ?? null,
                })
                if (stringSetter) {
                  stringSetter(valueAsString)
                }
              }}
              isRequired
            >
              <NumberInputField />
            </NumberInput>
          )}
        </FormControl>
        <FormControl flex={1}>
          <FormLabel>
            {estimatedLabel}
            {unit.length ? `（${unit}）` : ``}
          </FormLabel>
          <VStack height={10} alignItems="flex-start" pt={forCoverDistance ? 0 : 2}>
            {!!forDiameter && (
              <Text fontSize="md">
                {values?.estimated_value
                  ? `${findDiameterKeyByValue(values?.estimated_value)}（${values?.estimated_value || ''}）`
                  : '-'}
              </Text>
            )}
            {!!forCoverDistance && (
              <NumberInput
                min={0}
                max={9999}
                value={
                  coverDistanceEstimatedString && !coverDistanceEstimatedString.endsWith('.')
                    ? parseFloat(coverDistanceEstimatedString)
                    : coverDistanceEstimatedString || ''
                }
                onChange={(valueAsString, valueAsNumber) => {
                  setter({
                    estimated_value: valueAsNumber,
                    specified_value: values?.specified_value ?? null,
                  })
                  setCoverDistanceEstimatedString(valueAsString)
                }}
                isRequired
              >
                <NumberInputField />
              </NumberInput>
            )}
            {!forDiameter && !forCoverDistance && <Text fontSize="md">{values?.estimated_value || '-'}</Text>}
          </VStack>
        </FormControl>
        <FormControl flex={1}>
          <FormLabel>
            {t('main_canvas.inspection_sheet.values.difference', { ns: 'projects' })}
            {unit.length ? `（${unit}）` : ``}
          </FormLabel>
          <VStack height={10} alignItems="flex-start" pt={2}>
            <Text fontSize="md">
              {!values?.estimated_value || !values?.specified_value
                ? '-'
                : (values?.estimated_value || 0) - (values?.specified_value || 0) || '±0'}
            </Text>
          </VStack>
        </FormControl>
      </HStack>
    </VStack>
  )

  return (
    <Modal
      closeOnOverlayClick={!isLoading}
      isOpen={isOpen}
      onClose={() => onConfirm()}
      trapFocus={false}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          {/* 測点又は区別 */}
          <VStack height="100%" spacing={1} paddingTop={6}>
            <HStack width="100%" alignItems="baseline">
              <Text fontWeight="bold" fontSize="lg" whiteSpace="nowrap">
                {t('main_canvas.inspection_sheet.item', { ns: 'projects' })}
              </Text>
              <Divider />
            </HStack>
            <HStack justifyContent="flex-start" width="100%" paddingLeft={5} paddingTop={2}>
              <FormControl flex={1}>
                <FormLabel htmlFor="partName">
                  {t('main_canvas.inspection_sheet.create_item_modal.name', { ns: 'projects' })}
                </FormLabel>
                <InputGroup size="md">
                  <Input id="partName" value={partName} onChange={(e) => setPartName(e.target.value)} />
                </InputGroup>
              </FormControl>
              <Box flex={1} />
            </HStack>
          </VStack>
          {/* 鉄筋径 */}
          {renderValueInputs(
            diameter,
            t('main_canvas.inspection_sheet.inspection_quantity.diameter', { ns: 'projects' }),
            t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' }),
            t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' }),
            'mm',
            setDiameter,
            undefined,
            undefined,
            true
          )}
          {/* 鉄筋本数 */}
          {renderValueInputs(
            number,
            t('main_canvas.inspection_sheet.inspection_quantity.number', { ns: 'projects' }),
            t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' }),
            t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' }),
            t('main_canvas.inspection_sheet.unit_rebar', { ns: 'projects' }),
            setNumber,
            numberString,
            (valueAsString: string) => {
              setNumberString(valueAsString.replace(/[e.-]/, ''))
            }
          )}
          {/* 平均間隔 */}
          {renderValueInputs(
            meanDistance,
            t('main_canvas.inspection_sheet.inspection_quantity.mean_spacing', { ns: 'projects' }),
            t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' }),
            t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' }),
            'mm',
            setMeanDistance,
            meanDistanceString,
            setMeanDistanceString
          )}
          {/* かぶり */}
          {renderValueInputs(
            coverDistance,
            t('main_canvas.inspection_sheet.inspection_quantity.cover_thickness', { ns: 'projects' }),
            t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' }),
            t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' }),
            'mm',
            setCoverDistance,
            coverDistanceSpecifiedString,
            setCoverDistanceSpecifiedString,
            false,
            true
          )}
        </ModalBody>

        <ModalFooter mt={8} justifyContent="center">
          <Button disabled={isLoading} me={3} py={2} minW="100px" onClick={() => onConfirm()}>
            {t('main_canvas.inspection_sheet.create_item_modal.cancel', { ns: 'projects' })}
          </Button>
          {inspectionItem && project?.down_sampled_file.name && (
            <Button disabled={isLoading} me={3} py={2} minW="100px" onClick={() => onUpdateShapes()}>
              {t('main_canvas.inspection_sheet.create_item_modal.reselect_models', { ns: 'projects' })}
            </Button>
          )}
          <Button disabled={isLoading} me={3} py={2} minW="100px" colorScheme="primary" onClick={() => confirmValues()}>
            {inspectionItem
              ? t('main_canvas.inspection_sheet.create_item_modal.update', { ns: 'projects' })
              : t('main_canvas.inspection_sheet.create_item_modal.preview_sheet', { ns: 'projects' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

// Handle typing for props that is not required
ValuesModal.defaultProps = {
  valuesUpdatingInspectionItem: undefined,
  evaluatedDiameter: undefined,
  evaluatedMeanDistance: undefined,
  evaluatedCoverDistance: undefined,
}

export default ValuesModal
