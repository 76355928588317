import { FC, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Container,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import mixpanel from 'mixpanel-browser'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { AddIcon, HDotsIcon, InputEditorCancelIcon, InputEditorConfirmIcon, InputEditorEditIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'
import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { removeUserFromOrganization, renameOrganization } from 'services/Organizations'
import { decideOrganizationManagePermission } from 'services/Validation'

import InviteUserModal from './InviteUserModal'

const Organization: FC = () => {
  const { t } = useTranslation(['organization', 'common'])
  const { user } = useAuth0()
  const { organizations, fetchUserInfo } = useContext(UserContext)
  const { organization_id } = useParams<{ organization_id: string }>()
  const { showModal, handleError } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  const currentOrganization = organizations.find((organization) => organization.organization_id === organization_id)
  const isOrganizationOwner = !!user?.sub && currentOrganization?.owner_users.includes(user.sub)
  const isOrganizationMember = !!user?.sub && currentOrganization?.organization_members.includes(user.sub)

  const permissions = decideOrganizationManagePermission(isOrganizationOwner, isOrganizationMember)
  const isAllowedBrowse = permissions.BROWSE
  const isAllowedModify = permissions.MODIFY
  const isAllowedManageUsers = permissions.MANAGE_USERS

  const hasStripeSubscription = !!currentOrganization?.stripe_subscription_id
  // #1594 Temporary hide info
  // const isAllowedChangeLicense = isAllowedManageUsers && hasStripeSubscription

  const pricingPlan = currentOrganization?.pricing_plan || null

  // #1594 Temporary hide info
  // const licenseNumber = currentOrganization?.license_numbers
  // const assignedUsers = currentOrganization?.license_members || []
  // const assignableLicenseNumber = licenseNumber ? licenseNumber - assignedUsers.length : 0
  // const additionalFeatureActivatedUsers = currentOrganization?.additional_feature_organization_members || []

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [organizationName, setOrganizationName] = useState(currentOrganization?.organization_name || '')
  const [editingValue, setEditingValue] = useState('')
  const [editingField, setEditingField] = useState('')
  // #1594 Temporary hide info
  // const [isUpdateLicenseModalOpen, setIsUpdateLicenseModalOpen] = useState(false)
  // const [selectedMember, setSelectedMember] = useState({ email: '', userId: '' })

  useEffect(() => {
    setOrganizationName(currentOrganization?.organization_name || '')
  }, [currentOrganization])

  const showRemoveUserConfirmation = (userId: string) => {
    showModal({
      body: <Trans i18nKey="remove_modal.text" ns="organization" />,
      confirmText: t('remove_modal.confirm'),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          if (!currentOrganization) {
            return false
          }

          const token = await getAccessToken()
          if (!token) return false

          if (await removeUserFromOrganization(token, currentOrganization.organization_id, userId, handleError)) {
            // track with mixpanel
            mixpanel.track('Remove user from organization', {
              'Organization ID': currentOrganization.organization_id,
              'Target user ID': userId,
              'Target user email': currentOrganization.organization_members_email[userId],
            })

            fetchUserInfo()
            return true
          }
          return false
        })()
        return true
      },
      title: t('remove_modal.title'),
    })
  }

  const handleUpdateOrganization = async (fieldSetter: (value: string) => void) => {
    if (!currentOrganization) {
      return false
    }

    const token = await getAccessToken()
    if (!token) return false

    if (await renameOrganization(token, currentOrganization.organization_id, editingValue, handleError)) {
      // track with mixpanel
      mixpanel.track('Rename organization', {
        'Organization ID': currentOrganization.organization_id,
        'Organization name (old)': currentOrganization.organization_name,
        'Organization name (new)': editingValue,
      })

      fieldSetter(editingValue)
      return true
    }

    return false
  }

  /**
   * render input field with edit/confirm/cancel buttons
   *
   * @param {string} label text for label
   * @param {string} value text for displayed value
   * @param {?(newValue: string) => void} [setter] setter function for the value
   * @param {?() => void} [showMessageOnEdit] function to show modal when edit button is clicked
   */
  const renderInput = (
    label: string,
    value: string,
    setter?: (newValue: string) => void,
    showMessageOnEdit?: () => void
  ) => (
    <HStack fontSize="sm" fontWeight="semibold" my={1.5}>
      <Text color="secondary.400" minW="210px">
        {label}：
      </Text>
      {editingField === label && (
        <>
          <Input value={editingValue} maxW={200} size="sm" onChange={(e) => setEditingValue(e.target.value)} />
          <IconButton
            aria-label="edit"
            icon={<InputEditorCancelIcon />}
            color="secondary.500"
            backgroundColor="secondary.200"
            borderColor="secondary.00"
            borderWidth={1}
            onClick={() => {
              setEditingField('')
            }}
            width={6}
            height={6}
            minW={6}
            minH={6}
            borderRadius="full"
          />
          <IconButton
            aria-label="edit"
            icon={<InputEditorConfirmIcon />}
            color="white"
            backgroundColor="secondary.500"
            colorScheme="secondary"
            borderColor="secondary.00"
            borderWidth={1}
            onClick={async () => {
              setEditingField('')
              setEditingValue('')
              if (setter) await handleUpdateOrganization(setter)
            }}
            width={6}
            height={6}
            minW={6}
            minH={6}
            borderRadius="full"
            disabled={!editingValue}
          />
        </>
      )}
      {editingField !== label && (
        <>
          <Text>{value}</Text>
          {isAllowedModify && (setter || showMessageOnEdit) && (
            <IconButton
              aria-label="edit"
              icon={<InputEditorEditIcon />}
              color="white"
              borderColor="primary.500"
              backgroundColor="primary.500"
              colorScheme="primary"
              onClick={() => {
                if (showMessageOnEdit) {
                  showMessageOnEdit()
                } else {
                  setEditingField(label)
                  setEditingValue(value)
                }
              }}
              width={6}
              height={6}
              minW={6}
              minH={6}
              borderRadius="full"
            />
          )}
        </>
      )}
    </HStack>
  )

  return (
    <>
      <Navbar />

      {isAllowedBrowse && (
        <>
          <Container maxW={CONTAINER_MAX_WIDTH}>
            <PageHeading>{t('organization')}</PageHeading>
            {renderInput(t('organization_name'), organizationName, setOrganizationName)}
            {/* #1594 Temporary hide info */}
            {/* {hasStripeSubscription &&
              renderInput(t('active_license_count'), licenseNumber ? licenseNumber.toString() : '', undefined, () => {
                showModal({
                  title: t('update_license_count'),
                  body: <Trans i18nKey="update_message" ns="organization" />,
                  modalType: MODAL_TYPES.ERROR,
                })
              })}
            {hasStripeSubscription &&
              renderInput(
                t('available_license_count'),
                // 0 is falsy, so we need to add a condition to convert to string "0"
                assignableLicenseNumber || assignableLicenseNumber === 0 ? assignableLicenseNumber.toString() : ''
              )} */}
            {hasStripeSubscription &&
              renderInput(
                t('pricing_plan'),
                t(`pricing_systems.${pricingPlan || ''}`, { ns: 'common' }),
                undefined,
                () => {
                  showModal({
                    title: t('update_pricing_plan'),
                    body: <Trans i18nKey="update_message" ns="organization" />,
                    modalType: MODAL_TYPES.ERROR,
                  })
                }
              )}
            <Spacer />
            {isAllowedManageUsers && (
              <Button
                variant="outlinePrimary"
                size="md"
                rightIcon={<AddIcon />}
                mt={10}
                onClick={() => setIsInviteModalOpen(true)}
              >
                {t('add_user')}
              </Button>
            )}
            <TableContainer mb={10} mt={7}>
              <Table w="100%" size="sm" className="striped">
                <Thead fontSize="sm">
                  <Tr>
                    <Th>{t('user')}</Th>
                    {/* #1594 Temporary hide info */}
                    {/* <Th>{hasStripeSubscription && t('license_assignment.status')}</Th> */}
                    <Th>{t('role.role')}</Th>
                    <Th w="1%" />
                  </Tr>
                </Thead>
                <Tbody>
                  {currentOrganization?.organization_members.map((member_id) => (
                    <Tr key={member_id}>
                      <Td>{currentOrganization?.organization_members_email[member_id] || ''}</Td>
                      {/* #1594 Temporary hide info */}
                      {/* <Td>
                        {hasStripeSubscription &&
                          t(
                            `license_assignment.${decideLicenseAssignmentStatus(
                              member_id,
                              assignedUsers,
                              pricingPlan,
                              additionalFeatureActivatedUsers
                            )}`,
                            {
                              ns: 'organization',
                            }
                          )}
                      </Td> */}
                      <Td>
                        {currentOrganization?.owner_users.includes(member_id) ? t('role.owner') : t('role.member')}
                      </Td>
                      <Td>
                        <Menu autoSelect={false}>
                          <MenuButton
                            variant="ghost"
                            as={IconButton}
                            aria-label="Actions"
                            fontSize="lg"
                            icon={<HDotsIcon />}
                          />
                          <Portal>
                            <MenuList>
                              {/* #1594 Temporary hide info */}
                              {/* {hasStripeSubscription && (
                                <MenuItem
                                  isDisabled={!isAllowedChangeLicense}
                                  onClick={() => {
                                    setSelectedMember({
                                      email: currentOrganization?.organization_members_email[member_id],
                                      userId: member_id,
                                    })
                                    setIsUpdateLicenseModalOpen(true)
                                  }}
                                >
                                  {t('update_license_assignment')}
                                </MenuItem>
                              )} */}
                              <MenuItem
                                isDisabled={
                                  currentOrganization?.owner_users.includes(member_id) || // can not remove owner
                                  (!isAllowedManageUsers && member_id !== user?.sub) // can remove yourself even if you are not owner
                                  // TODO: update the logic above once the email invitation is implemented, or license management is implemented
                                }
                                onClick={() => showRemoveUserConfirmation(member_id)}
                              >
                                {t('remove')}
                              </MenuItem>
                            </MenuList>
                          </Portal>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Container>
          {isAllowedManageUsers && (
            <InviteUserModal
              isOpen={isInviteModalOpen}
              onConfirm={(result) => {
                setIsInviteModalOpen(false)
                if (result) {
                  fetchUserInfo()
                }
              }}
              organizationId={currentOrganization?.organization_id || ''}
            />
          )}
          {/* #1594 Temporary hide info */}
          {/* {isAllowedChangeLicense && (
            <UpdateLicenseAssignmentModal
              isOpen={isUpdateLicenseModalOpen}
              onConfirm={(result) => {
                setIsUpdateLicenseModalOpen(false)
                if (result) {
                  fetchUserInfo()
                }
              }}
              targetUserEmail={selectedMember.email}
              targetUserId={selectedMember.userId}
              accountCount={assignableLicenseNumber}
              status={decideLicenseAssignmentStatus(
                selectedMember.userId,
                assignedUsers,
                pricingPlan,
                additionalFeatureActivatedUsers
              )}
              currentOrganization={currentOrganization}
            />
          )} */}
        </>
      )}
    </>
  )
}
export default Organization
