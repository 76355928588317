import dayjs from 'dayjs'

import { CADFileType, CuboidDirection, CuboidKey, ExtraShapeKey, MeasureKey, ShapeKey } from 'interfaces/interfaces'

export const LANGUAGES = {
  EN: 'en',
  JA: 'ja',
} as const

export const SERVICE_STATUS_API_CALLING_THRESHOLD = 60000 // 60 seconds
export const UPLOAD_LIMIT_FILE_BYTE = 1024 ** 3 * 1 //* 1GB
export const UPLOAD_LIMIT_FILE_GIGABYTE = 1 // 1GB

export const EXPIRATION_TIME_I18N = 6 * 60 * 60 * 1000 // 6 hours in milliseconds
export const COOKIE_EXPIRE = dayjs().add(30, 'day').toDate()
export const EDITOR_BACKGROUND_COOKIE_NAME = 'editor.backgroundColor'
export const EDITOR_MEAN_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME = 'editor.meanDistanceCriterionUnitIndex'
export const EDITOR_COVER_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME = 'editor.coverDistanceCriterionUnitIndex'
export const SELECTED_ORGANIZATION_COOKIE_NAME = 'selectedOrganization'

export const MODAL_TYPES = {
  ALERT: 'ALERT',
  ERROR: 'ERROR',
  CONFIRMATION: 'CONFIRMATION',
  CONFIRMATION_CRITICAL: 'CONFIRMATION_CRITICAL',
}

export const AXIOS_ERROR_CODE: Record<string, string> = {
  ERR_BAD_OPTION_VALUE: '00',
  ERR_BAD_OPTION: '01',
  ECONNABORTED: '02',
  ETIMEDOUT: '03',
  ERR_NETWORK: '04',
  ERR_FR_TOO_MANY_REDIRECTS: '05',
  ERR_DEPRECATED: '06',
  ERR_BAD_RESPONSE: '07',
  ERR_BAD_REQUEST: '08',
  ERR_CANCELED: '09',
  ERR_NOT_SUPPORT: '10',
  ERR_INVALID_URL: '11',
} as const

export const API_PROCESS = [
  'ACTIVATE_ADDITIONAL_FEATURE',
  'ADD_BLUEPRINT',
  'ADD_IFC',
  'ASSIGN_LICENSE_TO_USER',
  'CREATE_PROJECT',
  'CREATE_COMMENT_REPLY',
  'CREATE_MASK_REGION',
  'CREATE_PROJECT',
  'CREATE_PROJECT_GROUP',
  'CREATE_SHAPE_GROUP',
  'DELETE_BLUEPRINT',
  'DELETE_COMMENT',
  'DELETE_COMMENT_REPLY',
  'DELETE_DISTANCE',
  'DELETE_IFC',
  'DELETE_INVITED_USER_FROM_PROJECT_GROUP',
  'DELETE_MASK_REGION',
  'DELETE_OVERLAP',
  'DELETE_PROJECT',
  'DELETE_PROJECT_GROUP',
  'DELETE_SHAPES',
  'DELETE_SHAPE_GROUP',
  'DETECT_SHAPES',
  'DOWNLOAD_CAD_FILE',
  'DOWNLOAD_XML_FILE',
  'EVALUATE_COVER_DISTANCE',
  'EVALUATE_MEAN_DISTANCE',
  'EVALUATE_OVERLAP',
  'EVALUATE_SPACER',
  'GET_ACCESS_TOKEN',
  'GET_BILLING_RECORDS',
  'GET_BLUEPRINTS',
  'GET_COMMENT_REPLIES',
  'GET_COMMENTS',
  'GET_DISTANCES',
  'GET_DOWN_SAMPLED_FILE',
  'GET_IFC_FILES',
  'GET_INSPECTION_SHEET',
  'GET_MASK_REGION',
  'GET_OVERLAPS',
  'GET_PROJECT_GROUPS',
  'GET_SERVICE_STATUS',
  'GET_SHAPES',
  'GET_SHAPE_GROUPS',
  'GET_SUBSCRIPTIONS',
  'GET_SUBSCRIPTION_INVOICES',
  'GET_USER',
  'INVITE_USER',
  'INVITE_USER_TO_PROJECT_GROUP',
  'INVITE_USER_TO_ORGANIZATION',
  'IMPORT_XML',
  'MODIFY_INSPECTION_SHEET',
  'REGISTER_USER',
  'REMOVE_USER_FROM_ORGANIZATION',
  'SAVE_DISTANCES',
  'SAVE_OVERLAPS',
  'SAVE_SHAPES',
  'STRIP_LICENSE_FROM_USER',
  'UPDATE_COMMENT',
  'UPDATE_COMMENT_REPLY',
  'UPDATE_DISTANCE',
  'UPDATE_PROJECT_GROUP',
  'UPDATE_SHAPE_GROUP',
  'UPDATE_USER',
  'UPLOAD_FILE',
]

// object whose key and the values are the same (API process names)
export const API_PROCESS_MAP: Record<string, string> = Object.fromEntries(API_PROCESS.map((key) => [key, key]))

export const USER_TYPES = {
  ADMIN: 'admin',
  PAYING_USER: 'paying_user',
  NON_PAYING_USER: 'non_paying_user',
  BETA_USER: 'beta_user',
  BASE_USER: 'base_user',
}

export type USER_TYPES_VALUE = typeof USER_TYPES[keyof typeof USER_TYPES]

export const EDITOR_TOOLS = {
  MOVE: 'MOVE',
  COMMENT: 'COMMENT',
  COMMENT_CUBOID: 'COMMENT_CUBOID',
  CYLINDER: 'CYLINDER',
  CYLINDER_CUBOID: 'CYLINDER_CUBOID',
  DISTANCE: 'DISTANCE',
  PCD_TRIM_CUBOID: 'PCD_TRIM_CUBOID',
  PLANE: 'PLANE',
  PLANE_VIRTUAL: 'PLANE_VIRTUAL',
  TORUS: 'TORUS',
  TORUS_CUBOID: 'TORUS_CUBOID',
  SPACER_ANNOTATION: 'SPACER_ANNOTATION',
}

export const IFC_REGISTER_TOOLS = {
  REGISTER: 'REGISTER',
}

export const BLUEPRINT_TOOLS = {
  COMMENT: 'COMMENT',
}
export const EDITOR_SUB_TOOLS = {
  CUBOID: {
    ROTATE: 'rotate',
    SCALE: 'scale',
    TRANSLATE: 'translate',
  },
}
export const EDITOR_TOOL_CURSOR_CLASSES: Record<string, string> = {
  MOVE: 'tooling-move',
  CYLINDER: 'tooling-crosshair',
  CYLINDER_CUBOID: 'tooling-crosshair',
  DISTANCE: 'tooling-crosshair',
  PCD_TRIM_CUBOID: 'tooling-crosshair',
  PLANE: 'tooling-crosshair',
  PLANE_VIRTUAL: 'tooling-crosshair',
  TORUS: 'tooling-crosshair',
  TORUS_CUBOID: 'tooling-crosshair',
  COMMENT_CUBOID: 'tooling-crosshair',
  SPACER_ANNOTATION: 'tooling-crosshair',
}
export const DEFAULT_EDITOR_TOOL = EDITOR_TOOLS.MOVE
export const DEFAULT_BLUEPRINT_TOOL = ''
export const DEFAULT_IFC_REGISTER_TOOL = ''
export const DEFAULT_EDITOR_SUB_TOOL = {
  CUBOID: EDITOR_SUB_TOOLS.CUBOID.SCALE,
}

export const MAX_EDITOR_LAYERS = 999

export const getPrecision = (step: number): number => String(step).split('.')[1].length
export const EDITOR_COORD_INPUT_STEP = 0.000001
export const EDITOR_COORD_INPUT_PRECISION = getPrecision(EDITOR_COORD_INPUT_STEP)
export const EDITOR_DIAMETER_INPUT_STEP = 0.1
export const EDITOR_DIAMETER_INPUT_PRECISION = getPrecision(EDITOR_DIAMETER_INPUT_STEP)
export const EDITOR_DIAMETER_INPUT_MIN = 0.1
export const EDITOR_DIAMETER_INPUT_MAX = 100
export const EDITOR_DIAMETER_INPUT_UNIT = 'mm'
export const MILLIMETER_SCALE = 1000
export const EDITOR_BASE_LINE_THICKNESS = 0.005
// default size of the points of pcd in pixels
export const POINT_SIZE_DEFAULT = 0.005

export const EDITOR_SHAPES_SITUATIONS = {
  CYLINDERS_ON_AXIS: 'cylinders_on_axis',
  TORI_ON_AXIS: 'tori_on_axis',
  CYLINDERS_ON_ARC: 'cylinders_on_arc',
}

// unit is millimeter
export const DIAMETERS: Record<string, number> = {
  D10: 9.53,
  D13: 12.7,
  D16: 15.9,
  D19: 19.1,
  D22: 22.2,
  D25: 25.4,
  D29: 28.6,
  D32: 31.8,
  D35: 34.9,
  D38: 38.1,
  D41: 41.3,
  D51: 50.8,
}

export const EDITOR_DECIMAL_BASE = '0.001' // to keep digits up to 1 [mm]

export const EDITOR_SHAPE_KEYS: Record<string, ShapeKey> = {
  CYLINDERS: 'cylinders',
  PLANES: 'planes',
  TORI: 'tori',
}
export const EDITOR_EXTRA_SHAPE_KEYS: Record<string, ExtraShapeKey> = {
  RHOMBI: 'rhombi',
}
export const EDITOR_MEASURE_KEYS: Record<string, MeasureKey> = {
  DISTANCE: 'distance',
  OVERLAP: 'overlap',
  DETECTED_CYLINDERS_DISTANCE: 'detectedCylindersDistance',
  DETECTED_TORI_DISTANCE: 'detectedToriDistance',
  DETECTED_PLANES_DISTANCE: 'detectedPlanesDistance',
  SPACER_ANNOTATION: 'spacerAnnotation',
}
export const EDITOR_CUBOID_KEY: CuboidKey = 'cuboid'
export const EDITOR_TOOL_KEYS = {
  [EDITOR_TOOLS.CYLINDER]: EDITOR_SHAPE_KEYS.CYLINDERS,
  [EDITOR_TOOLS.CYLINDER_CUBOID]: EDITOR_SHAPE_KEYS.CYLINDERS,
  [EDITOR_TOOLS.PLANE]: EDITOR_SHAPE_KEYS.PLANES,
  [EDITOR_TOOLS.PLANE_VIRTUAL]: EDITOR_SHAPE_KEYS.PLANES,
  [EDITOR_TOOLS.TORUS]: EDITOR_SHAPE_KEYS.TORI,
  [EDITOR_TOOLS.TORUS_CUBOID]: EDITOR_SHAPE_KEYS.TORI,
}
// cylinder requires 2 points. Torus, plane: 3 points
export const EDITOR_REQUIRED_ANCHORS: Record<ShapeKey | CuboidKey | MeasureKey, number> = {
  cylinders: 2,
  planes: 3,
  tori: 3,
  cuboid: 4,
  spacerAnnotation: 4,
  distance: 2,
  overlap: 2,
  detectedCylindersDistance: 2,
  detectedToriDistance: 2,
  detectedPlanesDistance: 2,
}

export const EDITOR_CUBOID_DIRECTIONS: Record<string, CuboidDirection> = {
  X: 'x',
  Y: 'y',
  Z: 'z',
}
export const EDITOR_CUBOID_DEFAULT_DIRECTION = EDITOR_CUBOID_DIRECTIONS.Z

export const EDITOR_SHAPE_TEMP_ID_PREFIX = 'temp_'
export const JOBS_WATCHING_INTERVAL = 5000 // 5 seconds

export const EDITOR_COLLAPSE_TYPES = {
  settings: 'settings',
  detected: 'detected',
  detecting: 'detecting',
  diameter: 'diameter',
  distance: 'distance',
  overlap: 'overlap',
  maskRegion: 'maskRegion',
  spacerAnnotation: 'spacerAnnotation',
  spacerAnchor: 'spacerAnchor',
  ifcFiles: 'ifcFiles',
}

export const EDITOR_POINT_SIZE_INTERVAL = 0.001 // 1mm

export const CAD_FILE_TYPES: Record<string, CADFileType> = {
  ifc: 'ifc',
  dxf: 'dxf',
}

export const EDITOR_MOUSE_EVENT_DELAY = 50 // 0.05ms
export const EDITOR_MOUSE_DRAG_THRESHOLD = 10 // prevent dragging event catching on selecting an anchor

export const EDITOR_DOUBLE_CLICK_INTERVAL = 400 // less than 0.4 second
export const EDITOR_DOUBLE_CLICK_THRESHOLD = 3 // less than 3 pixels
export const EDITOR_CUBOID_QUICK_SIZE = 3 // * 3 times of the current diameter
export const EDITOR_CUBOID_MIN_SIZE = 0.5 // * 0.5 times of the current diameter each side

export const BLUEPRINT_MIN_SCALE = 25 // 25%
export const BLUEPRINT_MAX_SCALE = 500 // 500%
export const BLUEPRINT_SCALE_STEP = 10 // 10%

export const BLUEPRINT_MOUSE_EVENT_DELAY = 100 // 0.1ms

export const BLUEPRINT_COMMENT_AREA_MIN_SIZE = 32

export const EDITOR_CANVAS_CONTAINER_ID = 'editor-main-canvas-container'

export const PRICING_PLANS = {
  CONSTANT: 'constant',
  PAY_ON_DEMAND: 'pay_on_demand',
} as const
export const SUBSCRIPTION_ALERT_REMAINING_DAYS = 5 // Days left to notify the user

export const INPUT_SUGGESTIONS: Record<string, string[]> = {
  工種名: ['擁壁工', 'カルバート工', '場所打杭工', '床版工', '橋脚工', '橋梁付属物工', '用水路工', '樋門樋管工'],
  工事名: [], // Get from all inspection sheets information
  種別: [], // Get from all inspection sheets information
  作成者: [], // Get from all inspection sheets information
}

export const INSPECTION_SHEET_TYPES = {
  REBAR: 'rebar',
  SPACER: 'spacer',
}

export const AUTO_DETECT_DUMMY_AMOUNT_ON_AXIS = 3
export const AUTO_DETECT_DUMMY_AMOUNT_ON_ARC = 8

// unit is millimeter
// TODO: tune this value to 50 mm or something more appropriate once we have the real data (pcd/ifc)
export const THRESHOLD_REGISTRATION_ERROR = 1000 // 50mm

export const Z_INDEX = {
  navbar: { nav_links: 999 },
  blueprint_viewer: {
    blueprint_viewer: 999,
    main_document: 2,
    comment_icon: 1,
    comment_static: 2,
    comment_moving: 3,
  },
  main_canvas: {
    comment_unplaced: 1,
    loading_pcd: 999,
    inspection_sheet: {
      input_form_box_date: 5,
      input_form_number: 2,
      icons_hstack: 2,
      suggestion_menu: 9,
      criterion_unit_switcher: 0,
      criterion_units: 1,
      inspection_item_hstack: 3,
      inspection_item_icons: 3,
      download_menu: 4,
    },
  },
  common: { comment_menu: 1, instruction_bar: 999, toolbar_button: 0 },
  ifc_register: {
    file_list_button: 999,
    loading_pcd: 998,
    loading_ifc: 998,
    box_ifc: 999,
    register_button: 999,
    buttons_after_registration: 999,
  },
}
export const Z_INDEX_RANGE = {
  default: [0, 9],
  anchor_point: [0, 0],
  camera_focus_point: [0, 0],
  comment_unplaced: [0, 0],
  comment_moving: [0, 1],
  comment_static: [0, 0],
}

export const EDITOR_MASK_POINT_OPACITY = 0.1

export const ERROR_SHAPE_GROUP: Record<string, string> = {
  PlaneSelected: 'PlaneSelected',
  DifferentShapesSelected: 'DifferentShapesSelected',
  ShapesAlreadyUsed: 'ShapesAlreadyUsed',
  DifferentDiameter: 'DifferentDiameter',
}

export const LICENSE_ASSIGNMENT_STATUS = {
  assigned: 'assigned',
  assigned_premium: 'assigned_premium',
  assigned_no_premium: 'assigned_no_premium',
  view_only: 'view_only',
} as const
