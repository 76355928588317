import { FC, useContext } from 'react'

import { Flex, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES, MAX_EDITOR_LAYERS } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { zeroPad } from 'services/Util'

import { spacerInFormationSelected } from '../store/editor'
import CollapsePanel from './components/CollapsePanel'
import LayerItem from './components/LayerItem'

const SpacerFramePanel: FC = () => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const { isLayerModifying, updateSpacerAnchorStatus } = useContext(EditorContext)

  const isExpanded = useSelector((state: RootState) =>
    state.editor.expandedPanels.includes(EDITOR_COLLAPSE_TYPES.spacerAnchor)
  )
  const { spacerAnnotationAnchors } = useSelector((state: RootState) => state.spacerAnnotation)
  const { selectedAnchor } = useSelector((state: RootState) => state.anchor)

  const updateLayerVisibility = (invisible: boolean, index: number) => {
    updateSpacerAnchorStatus({ invisible }, index)
  }
  const deleteLayer = (index: number) => {
    updateSpacerAnchorStatus({ deleted: true }, index)
  }

  const zeroPlaces = MAX_EDITOR_LAYERS.toString().length

  if (!spacerAnnotationAnchors?.filter((a) => !a.deleted).length) {
    return null
  }

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isExpanded ? 20 : 10}
    >
      <CollapsePanel
        title={t('main_canvas.panels.spacer.forming_grid', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.spacerAnchor}
      >
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          {spacerAnnotationAnchors.map(
            (anchor, anchorIndex) =>
              !anchor.deleted && (
                <LayerItem
                  disabled={isLayerModifying}
                  selected={selectedAnchor?.anchorIndex === anchorIndex}
                  deleteLayer={() => deleteLayer(anchorIndex)}
                  invisible={anchor.invisible}
                  key={`${t('main_canvas.panels.spacer.grid', { ns: 'projects' })} ${zeroPad(
                    anchorIndex + 1,
                    zeroPlaces
                  )}`}
                  label={t('main_canvas.panels.spacer.grid', { ns: 'projects' })}
                  updateVisibility={(invisible) => updateLayerVisibility(invisible, anchorIndex)}
                  onClick={() => {
                    dispatch(spacerInFormationSelected())
                  }}
                />
              )
          )}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default SpacerFramePanel
