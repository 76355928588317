import { FC } from 'react'

import { useFrame } from '@react-three/fiber'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { Vector3 } from 'three'

import { getCameraCuboidTarget, getCameraGlobalTarget } from 'services/Points'

/**
 * animate the camera center moving to target position
 *
 * @param {{ distance: any; position: any; center: any; finish: any; }} param0
 * @param {number} param0.distance distance from camera to target
 * @param {string} param0.position string representing the direction
 * @param {Vector3?} param0.center position of the target point
 * @param {() => void)} param0.finish callback function to be called when the animation is completed
 * @returns {*}
 */
const CameraCuboidAnimator: FC<{
  distance: number
  position: string
  center: Vector3 | undefined
  finish: () => void
}> = ({ distance, position, center, finish }) => {
  const { cuboid } = useSelector((state: RootState) => state.cuboid)

  // unit vectors for 6 directions
  const upVector = new Vector3(0, 0, 1)
  const downVector = new Vector3(0, 0, -1)
  const leftVector = new Vector3(-1, 0, 0)
  const rightVector = new Vector3(1, 0, 0)
  const frontVector = new Vector3(0, -1, 0)
  const backVector = new Vector3(0, 1, 0)

  const threshold = distance / 100

  useFrame((state) => {
    if (!position) return null

    let target
    switch (position) {
      case 'UP':
        target = getCameraGlobalTarget(upVector, distance, center)
        break
      case 'DOWN':
        target = getCameraGlobalTarget(downVector, distance, center)
        break
      case 'LEFT':
        target = getCameraGlobalTarget(leftVector, distance, center)
        break
      case 'RIGHT':
        target = getCameraGlobalTarget(rightVector, distance, center)
        break
      case 'FRONT':
        target = getCameraGlobalTarget(frontVector, distance, center)
        break
      case 'BACK':
        target = getCameraGlobalTarget(backVector, distance, center)
        break
      case 'CUBE-UP':
        target = getCameraCuboidTarget(upVector, distance, cuboid)
        break
      case 'CUBE-DOWN':
        target = getCameraCuboidTarget(downVector, distance, cuboid)
        break
      case 'CUBE-LEFT':
        target = getCameraCuboidTarget(leftVector, distance, cuboid)
        break
      case 'CUBE-RIGHT':
        target = getCameraCuboidTarget(rightVector, distance, cuboid)
        break
      case 'CUBE-FRONT':
        target = getCameraCuboidTarget(frontVector, distance, cuboid)
        break
      case 'CUBE-BACK':
        target = getCameraCuboidTarget(backVector, distance, cuboid)
        break
      default:
        break
    }

    if (target) {
      if (state.camera.position.distanceTo(target) <= threshold) {
        finish()
        return null
      }
      state.camera.position.lerp(target, 0.2)
      state.camera.updateProjectionMatrix()
    }

    return null
  })

  return <mesh />
}

export default CameraCuboidAnimator
