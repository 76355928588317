import { FC } from 'react'

import { VStack } from '@chakra-ui/react'

import { INFO_PANEL_WIDTH } from 'config/styles'

import DistancePanel from './DistancePanel'
import FramePanel from './FramePanel'
import IFCPanel from './IFCPanel'
import MaskingRegionsPanel from './MaskingRegionsPanel'
import OverlapPanel from './OverlapPanel'
import SettingPanel from './SettingPanel'
import SpacerAnnotationPanel from './SpacerAnnotationPanel'
import SpacerFramePanel from './SpacerFramePanel'
import PropertyPanel from './propertyPanel/PropertyPanel'
import ShapePanel from './shapePanel/ShapePanel'

const InfoPanels: FC<{ actionPanelHeight: number; isAllActionsDisabled: boolean; isAllowedModify: boolean }> = ({
  actionPanelHeight,
  isAllActionsDisabled,
  isAllowedModify,
}) => (
  <VStack
    color="secondary.400"
    fontSize="xs"
    position="absolute"
    right={0}
    spacing={1.5}
    top={0}
    width={INFO_PANEL_WIDTH}
    maxH={`calc(100svh - ${actionPanelHeight}px)`}
    overflow="hidden"
  >
    <SettingPanel />
    <VStack display={isAllActionsDisabled ? 'none' : 'flex'} spacing={1.5} width="100%" minH={0}>
      <IFCPanel />
      <MaskingRegionsPanel isAllowedModify={isAllowedModify} />
      <ShapePanel isAllowedModify={isAllowedModify} />
      <PropertyPanel />
      <FramePanel />
      <DistancePanel />
      <OverlapPanel />
      <SpacerAnnotationPanel isAllowedModify={isAllowedModify} />
      <SpacerFramePanel />
    </VStack>
  </VStack>
)

export default InfoPanels
