import { FC, useContext } from 'react'

import { Center } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { Vector3 } from 'three'

import { AnchorPointIcon } from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_MEASURE_KEYS, EDITOR_TOOLS, Z_INDEX_RANGE } from 'config/constants'

import { CuboidKey, MeasureKey, PointArray, ShapeKey } from 'interfaces/interfaces'

/**
 * Component for a point icon
 */
const PointIcon: FC<{
  point: PointArray
  anchorIndex: number
  pointIndex: number
  shapeKey: ShapeKey | MeasureKey | CuboidKey
}> = ({ point, anchorIndex, pointIndex, shapeKey }) => {
  const isJobRunning = useSelector((state: RootState) => state.editor.isJobRunning)
  const { selectedTool, isDragging, changeIsDragging, changeHoveredPoint, hoveredPoint } = useContext(EditorContext)

  const { selectedAnchor } = useSelector((state: RootState) => state.anchor)

  const isSelected = () => selectedAnchor?.shapeKey === shapeKey && selectedAnchor?.anchorIndex === anchorIndex

  const isHovered = () =>
    hoveredPoint?.shapeKey === shapeKey &&
    hoveredPoint?.anchorIndex === anchorIndex &&
    hoveredPoint.pointIndex === pointIndex

  const onPointerOver = () => {
    if (selectedTool !== EDITOR_TOOLS.MOVE || isDragging) {
      return
    }

    if (isJobRunning && shapeKey !== EDITOR_MEASURE_KEYS.DISTANCE) {
      return
    }

    changeHoveredPoint({
      anchorIndex,
      pointIndex,
      shapeKey,
    })
  }
  const onPointerOut = () => {
    if (selectedTool !== EDITOR_TOOLS.MOVE || isDragging) {
      return
    }

    changeHoveredPoint(undefined)
  }

  return (
    <Html
      position={new Vector3(...point)}
      style={{
        transform: 'translate(-50%, -50%)',
        width: '20px',
        height: '20px',
        position: 'relative',
      }}
      zIndexRange={Z_INDEX_RANGE.anchor_point}
    >
      <Center
        style={{
          transform: isHovered() || isSelected() ? 'scale(1.2) ' : '',
        }}
        onPointerOver={() => {
          if (!isMobile) {
            onPointerOver()
          }
        }}
        onPointerOut={() => {
          if (!isMobile) {
            onPointerOut()
          }
        }}
        onPointerDown={() => {
          if (isMobile) {
            onPointerOver()
            changeIsDragging(true)
          }
        }}
        onPointerUp={() => {
          if (isMobile) {
            onPointerOut()
            changeIsDragging(false)
          }
        }}
      >
        <AnchorPointIcon className={isHovered() || isSelected() ? 'red' : ''} />
      </Center>
    </Html>
  )
}

export default PointIcon
