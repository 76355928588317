import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useCookies } from 'react-cookie'

import { API_PROCESS_MAP, SELECTED_ORGANIZATION_COOKIE_NAME } from 'config/constants'

import { ProjectGroup, ProjectState, ProjectsContextProps } from 'interfaces/interfaces'

import * as ProjectGroupService from 'services/ProjectGroups'

import { GlobalModalContext } from './GlobalModal'
import { UserContext } from './Users'

// ref: https://wanago.io/2020/09/28/react-context-api-hooks-typescript/
//* アプリ全体としてプロジェクト情報を管理するプロバイダーの生成
export const ProjectsContext = createContext<ProjectsContextProps>({
  projectGroups: [],
  invitedProjectGroups: [],
  total_size: 0,
  projectGroupsLoaded: false,
  getProjectGroup: () => undefined,
  getProjectGroups: async () => Promise.resolve(null),
  appendProjectGroup: () => null,
  putProjectGroup: () => null,
})

//* プロバイダーに渡すプロジェクト情報の項目や操作処理
export function useProjectsContext(): ProjectsContextProps {
  const { handleError } = useContext(GlobalModalContext)
  const { userLoaded } = useContext(UserContext)
  const [cookies] = useCookies([SELECTED_ORGANIZATION_COOKIE_NAME])

  const [state, setState] = useState<ProjectState>({
    projectGroups: [],
    invitedProjectGroups: [],
    total_size: 0,
    projectGroupsLoaded: false,
  })
  const { getAccessTokenSilently } = useAuth0()

  //* プロジェクトデータ取得
  const getProjectGroups = useCallback(
    async (
      user_id?: string
    ): Promise<{ projectGroups: ProjectGroup[]; invitedProjectGroups: ProjectGroup[]; totalSize: number } | null> => {
      const access_token = await getAccessTokenSilently().catch((err) => {
        handleError(err, API_PROCESS_MAP.GET_ACCESS_TOKEN)
        return null
      })

      if (!access_token) {
        return null
      }

      const [projectGroupsRes, invitedProjectGroupsRes] = await Promise.all([
        ProjectGroupService.getProjectGroups(
          access_token,
          cookies[SELECTED_ORGANIZATION_COOKIE_NAME] as string,
          user_id,
          handleError,
          true
        ),
        ProjectGroupService.getInvitedProjectGroups(access_token, user_id, handleError, true),
      ])

      if (!projectGroupsRes || !invitedProjectGroupsRes) {
        return null
      }

      setState((prevState) => ({
        ...prevState,
        projectGroups: projectGroupsRes.projectGroups,
        invitedProjectGroups: invitedProjectGroupsRes.projectGroups,
        total_size: projectGroupsRes.total_size,
        projectGroupsLoaded: true,
      }))
      return {
        projectGroups: projectGroupsRes.projectGroups,
        invitedProjectGroups: invitedProjectGroupsRes.projectGroups,
        totalSize: projectGroupsRes.total_size,
      }
    },
    [cookies, getAccessTokenSilently, handleError]
  )

  //* プロバイダー内のプロジェクトデータを取得
  const getProjectGroup = useCallback(
    (project_group_id: string): ProjectGroup | undefined =>
      state.projectGroups.find((pj) => pj.project_group_id === project_group_id),
    [state.projectGroups]
  )

  //* プロジェクトデータの追加
  const appendProjectGroup = useCallback(
    (projectGroup: ProjectGroup) => {
      const deepCopyProjectGroups = JSON.parse(JSON.stringify(state.projectGroups)) as ProjectGroup[]
      setState((prevState) => ({ ...prevState, projectGroups: [projectGroup, ...deepCopyProjectGroups] }))
    },
    [state.projectGroups]
  )

  //* プロジェクトデータの置換
  const putProjectGroup = useCallback(
    (newProjectGroup: ProjectGroup) => {
      const deepCopyProjectGroups = JSON.parse(JSON.stringify(state.projectGroups)) as ProjectGroup[]
      const index = state.projectGroups.findIndex((pj) => pj.project_group_id === newProjectGroup.project_group_id)
      deepCopyProjectGroups.splice(index, 1, newProjectGroup)

      setState((prevState) => ({ ...prevState, projectGroups: deepCopyProjectGroups }))
    },
    [state.projectGroups]
  )

  useEffect(() => {
    if (userLoaded) {
      void getProjectGroups()
    }
  }, [cookies, getProjectGroups, userLoaded])

  return {
    projectGroups: state.projectGroups,
    invitedProjectGroups: state.invitedProjectGroups,
    total_size: state.total_size,
    projectGroupsLoaded: state.projectGroupsLoaded,
    getProjectGroup,
    getProjectGroups,
    appendProjectGroup,
    putProjectGroup,
  }
}
