import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Center,
  Container,
  HStack,
  Link,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import mixpanel from 'mixpanel-browser'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { PDFFileIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES, PRICING_PLANS, SELECTED_ORGANIZATION_COOKIE_NAME } from 'config/constants'
import { CONTAINER_MAX_WIDTH, PAYMENT_STATUS_COLOR } from 'config/styles'

import { BillingRecord, Subscription, SubscriptionInvoice } from 'interfaces/interfaces'

import { getInvoices } from 'services/Organizations'
import {
  activateAdditionalFeature,
  activateAdditionalFeatureWithStripeSub,
  formatMonth,
  getBillingRecords,
  getUserSubscription,
} from 'services/Usage'

dayjs.extend(localeData)

const UsageDashboard: FC = () => {
  const { t, i18n } = useTranslation(['usage_dashboard', 'common'])
  const navigate = useNavigate()
  const { user } = useAuth0()
  const { showModal, handleError } = useContext(GlobalModalContext)
  const { userLoaded, subscriptionId, organizations, getAccessToken, userTypeForOrganizations } =
    useContext(UserContext)
  const [cookies] = useCookies([SELECTED_ORGANIZATION_COOKIE_NAME])

  const currentOrganization = organizations.find(
    (organization) => organization.organization_id === cookies[SELECTED_ORGANIZATION_COOKIE_NAME]
  )
  const hasStripeSubscription = !!currentOrganization?.stripe_subscription_id
  const subscriptionEndDate = currentOrganization?.subscription_end_date
    ? dayjs(currentOrganization.subscription_end_date)
    : null
  // hard code JPY as currency for now. We can fetch currency from API once we get foreign customers.
  const monthlyAdditionalPrice = `${currentOrganization?.price_data?.additional_feature.unit_amount || 0}円`
  const monthlyAdditionalPriceTax = currentOrganization?.price_data?.additional_feature.tax_behavior || ''

  const [shouldShowSubscribeButton, setShouldShowSubscribeButton] = useState(false)
  const [billingRecords, setBillingRecords] = useState<BillingRecord[]>([])
  const [userSubscription, setUserSubscription] = useState<Subscription | null>(null)
  // whether there is a billing record for the current month
  const [existsBillForCurrentMonth, setExistsBillForCurrentMonth] = useState(false)
  const [invoices, setInvoices] = useState<SubscriptionInvoice[]>([])
  // whether there is a Stripe invoice for the current month
  const [existsInvoiceForCurrentMonth, setExistsInvoiceForCurrentMonth] = useState(false)

  const checkStatus = useCallback(async () => {
    // TODO: The lines below are to be removed once all the subscriptions are migrated to Stripe
    if (!hasStripeSubscription && user?.sub && subscriptionId) {
      const token = await getAccessToken()
      if (!token) {
        return false
      }

      const subscription = await getUserSubscription(token, user.sub, subscriptionId, handleError)
      setUserSubscription(subscription)
      setShouldShowSubscribeButton(subscription?.subscription_detail.additional_feature_enabled === false)
    }

    if (hasStripeSubscription && userTypeForOrganizations[currentOrganization.organization_id] === 'base_user') {
      setShouldShowSubscribeButton(true)
    }

    return true
  }, [
    getAccessToken,
    handleError,
    subscriptionId,
    user,
    hasStripeSubscription,
    currentOrganization,
    userTypeForOrganizations,
  ])

  // TODO: The lines below are to be removed once all the subscriptions are migrated to Stripe
  const fetchBillingRecords = useCallback(async () => {
    if (userLoaded) {
      const token = await getAccessToken()
      if (!token) {
        return false
      }

      const records = await getBillingRecords(token, handleError)
      // check if the last record is for current month and year
      // dayjs month starts from 0
      const currentMonth = dayjs().month() + 1
      const currentYear = dayjs().year()
      setBillingRecords(records || [])
      setExistsBillForCurrentMonth(
        records?.length ? records[0].billing_year === currentYear && records[0].billing_month === currentMonth : false
      )
    }

    return true
  }, [getAccessToken, handleError, userLoaded])

  const fetchInvoices = useCallback(async () => {
    if (userLoaded && currentOrganization?.organization_id) {
      const token = await getAccessToken()
      if (!token) {
        return false
      }

      const records = await getInvoices(token, currentOrganization.organization_id, handleError)
      // check if the last record is for current month and year
      // dayjs month starts from 0
      const currentMonth = dayjs().month() + 1
      const currentYear = dayjs().year()
      setInvoices(records || [])
      setExistsInvoiceForCurrentMonth(
        records?.length
          ? dayjs(records[0].created).year() === currentYear && dayjs(records[0].created).month() + 1 === currentMonth
          : false
      )
    }

    return true
  }, [currentOrganization, getAccessToken, handleError, userLoaded])

  // fetch user subscription status
  useEffect(() => {
    void checkStatus()
  }, [checkStatus, subscriptionId, user])

  // fetch invoice records
  useEffect(() => {
    if (hasStripeSubscription) {
      void fetchInvoices()
    } else {
      void fetchBillingRecords()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStripeSubscription, userLoaded])

  const showActivationConfirmation = () => {
    showModal({
      body: t('activate_modal.text', {
        ns: 'usage_dashboard',
        additional_price: monthlyAdditionalPrice,
        context: monthlyAdditionalPriceTax,
      }),
      confirmText: t('activate_modal.confirm', { ns: 'usage_dashboard' }),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        const activateFeaturesApi = async () => {
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          let result = null
          if (hasStripeSubscription && currentOrganization) {
            result = await activateAdditionalFeatureWithStripeSub(
              token,
              currentOrganization.organization_id,
              handleError
            )
          }
          // TODO: The lines below are to be removed once all the subscriptions are migrated to Stripe
          else {
            if (!user?.sub || !subscriptionId) {
              return false
            }
            result = await activateAdditionalFeature(token, user.sub, subscriptionId, handleError)
          }

          if (!result) {
            return false
          }

          // track with mixpanel
          mixpanel.track('Activate full feature', {
            'Activation date': dayjs().format(),
            'Organization ID': currentOrganization?.organization_id,
            'With Stripe subscription': hasStripeSubscription,
          })

          // Refetch the data
          await Promise.all([checkStatus(), fetchBillingRecords()])
          return true
        }

        void activateFeaturesApi()

        return true
      },
      title: t('activate_modal.title', { ns: 'usage_dashboard' }),
    })
  }

  // TODO: The lines below are to be removed once all the subscriptions are migrated to Stripe
  const getAdditionalPrice = (record: BillingRecord) => {
    if (record.pricing_system === PRICING_PLANS.CONSTANT) {
      return '-'
    }
    if (record.pricing_system === PRICING_PLANS.PAY_ON_DEMAND) {
      const price = (record.billing_detail.price.additional || 0) * (1 + record.billing_detail.tax_rate)
      return price.toLocaleString('en-US')
    }
    return '-'
  }

  // TODO: The lines below are to be removed once all the subscriptions are migrated to Stripe
  const getActivationStatus = (record: BillingRecord) => {
    if (record.pricing_system === PRICING_PLANS.CONSTANT) {
      return '-'
    }
    if (record.pricing_system === PRICING_PLANS.PAY_ON_DEMAND) {
      return record.billing_detail.price.additional
        ? t('yes', { ns: 'usage_dashboard' })
        : t('no', { ns: 'usage_dashboard' })
    }
    return '-'
  }

  const formatPriceForCurrentMonth = () => {
    // if the organization-based Stripe invoice exists for the current month, show the full price
    if (existsInvoiceForCurrentMonth) {
      return invoices[0].amount_due.toLocaleString('en-US')
    }

    // if the user-based bill exists for the current month, show the additional price (not the full price)
    if (existsBillForCurrentMonth) {
      return getAdditionalPrice(billingRecords[0])
    }

    // otherwise, show nothing
    return '-'
  }

  // leave the page if the user has no subscription and no stripe subscription
  useEffect(() => {
    if (userLoaded && !subscriptionId && !hasStripeSubscription) {
      navigate('/')
    }
  }, [hasStripeSubscription, navigate, subscriptionId, userLoaded])

  if (!subscriptionId && !hasStripeSubscription) {
    return null
  }
  const texts: Record<string, string> = t('pricing_systems', {
    ns: 'common',
    returnObjects: true,
  })

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH}>
        <PageHeading>{t('usage', { ns: 'usage_dashboard' })}</PageHeading>
        <HStack w={{ sm: '100%', md: '50%' }} alignItems="flex-start">
          <VStack fontSize="sm" fontWeight="semibold" my={1.5} alignItems="stretch">
            <HStack spacing={8}>
              <Text>
                {t('current_month', { ns: 'usage_dashboard' })} (
                {
                  formatMonth(dayjs().year(), dayjs().month() + 1, undefined, i18n.language) // add 1 as month in dayjs starts from 0
                }
                )
              </Text>
              {subscriptionEndDate && (
                <HStack>
                  <Text>{t('subscription_end_date', { ns: 'usage_dashboard' })}：</Text>
                  <Text>
                    {
                      formatMonth(
                        subscriptionEndDate.year(),
                        subscriptionEndDate.month() + 1,
                        subscriptionEndDate.date(),
                        i18n.language
                      ) // add 1 as month in dayjs starts from 0
                    }
                  </Text>
                </HStack>
              )}
            </HStack>
            <HStack>
              <Text color="secondary.400" minW="92px">
                {t('plan', { ns: 'usage_dashboard' })}：
              </Text>
              <Text flex={1}>
                {texts[
                  hasStripeSubscription
                    ? currentOrganization?.pricing_plan || ''
                    : userSubscription?.pricing_system || ''
                ] || '-'}
              </Text>
            </HStack>
            <HStack>
              <Text color="secondary.400" minW="92px">
                {hasStripeSubscription
                  ? t('charge_amount', { ns: 'usage_dashboard' })
                  : t('additional_fee', { ns: 'usage_dashboard' })}
                ：
              </Text>
              <Text>
                {t('price_text', {
                  ns: 'usage_dashboard',
                  price: formatPriceForCurrentMonth(),
                })}
              </Text>
            </HStack>
          </VStack>
          <Spacer />
          {shouldShowSubscribeButton && (
            <Button size="md" mt={10} mb={7} colorScheme="primary" onClick={showActivationConfirmation}>
              {t('activate_premium', { ns: 'usage_dashboard' })}
            </Button>
          )}
        </HStack>
        <Text color="secondary.400" fontSize="80%" my={5}>
          {t('tax_included', { ns: 'usage_dashboard' })}
        </Text>
        <TableContainer mb={10}>
          <Table w="100%" size="sm" className="striped">
            <Thead fontSize="sm">
              <Tr>
                <Th w="10%" textAlign="right">
                  {t('month', { ns: 'usage_dashboard' })}
                </Th>
                <Th textAlign="right">{t('plan', { ns: 'usage_dashboard' })}</Th>
                {!hasStripeSubscription ? (
                  <Th textAlign="right">{t('used_premium', { ns: 'usage_dashboard' })}</Th>
                ) : null}
                <Th textAlign="right">
                  {hasStripeSubscription
                    ? t('charge_amount', { ns: 'usage_dashboard' })
                    : t('additional_fee', { ns: 'usage_dashboard' })}
                  ［¥］
                </Th>
                {hasStripeSubscription ? (
                  <Th textAlign="right">{t('payment_status.status', { ns: 'usage_dashboard' })}</Th>
                ) : null}
                {hasStripeSubscription ? (
                  <Th textAlign="right">{t('payment_due_date', { ns: 'usage_dashboard' })}</Th>
                ) : null}
                {hasStripeSubscription ? <Th textAlign="center">{t('invoice', { ns: 'usage_dashboard' })}</Th> : null}
              </Tr>
            </Thead>
            <Tbody>
              {billingRecords.map((record) => (
                <Tr key={record.billing_id}>
                  <Td textAlign="right">
                    {formatMonth(record.billing_year, record.billing_month, undefined, i18n.language)}
                  </Td>
                  <Td textAlign="right">{texts[record.pricing_system] || '-'}</Td>
                  <Td textAlign="right">{getActivationStatus(record)}</Td>
                  <Td textAlign="right">{getAdditionalPrice(record)}</Td>
                </Tr>
              ))}
              {invoices.map((record) => {
                const createdDate = dayjs(record.created)
                return (
                  <Tr key={record.invoice_pdf}>
                    <Td textAlign="right">
                      {formatMonth(createdDate.year(), createdDate.month() + 1, undefined, i18n.language)}
                    </Td>
                    <Td textAlign="right">{texts[record.pricing_plan] || '-'}</Td>
                    <Td textAlign="right">{record.amount_due.toLocaleString('en-US')}</Td>
                    <Td textAlign="right" color={PAYMENT_STATUS_COLOR[record.status || 'default']}>
                      {record.status ? t(`payment_status.${record.status}`, { ns: 'usage_dashboard' }) : '-'}
                    </Td>
                    <Td textAlign="right">{record.due_date ? dayjs(record.due_date).format('YYYY/MM/DD') : '-'}</Td>
                    <Td>
                      {record.invoice_pdf ? (
                        <Center>
                          <Link
                            isExternal
                            variant="underline"
                            href={record.invoice_pdf}
                            onClick={() => {
                              // track with mixpanel
                              mixpanel.track('Download invoice file', {
                                'Target URL': record.invoice_pdf,
                                'Organization ID': currentOrganization?.organization_id,
                                'Invoice created date': createdDate.format(),
                                'Pricing plan': record.pricing_plan,
                                'Amount due': record.amount_due,
                                Status: record.status,
                                'Due date': dayjs(record.due_date).format(),
                              })
                            }}
                          >
                            <PDFFileIcon />
                          </Link>
                        </Center>
                      ) : (
                        '-'
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </>
  )
}

export default UsageDashboard
