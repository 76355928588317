import { FC, useContext, useEffect, useState } from 'react'

import { Button, Container, FormControl, FormLabel, HStack, Input, Text, VStack } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import FullFeatureSuggestionText from 'components/StickyText/FullFeatureSuggestionText'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { CheckCircleIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'

import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { registerUser } from 'services/Users'
import { isValidEmail } from 'services/Validation'

const Register: FC = () => {
  const { search } = useLocation()
  const { t, i18n } = useTranslation(['register'])

  const parameters = new URLSearchParams(search)
  const email_address = parameters.get('email_address')

  const { handleError } = useContext(GlobalModalContext)

  const [emailAddress, setEmailAddress] = useState('')
  const [personalName, setPersonalNme] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isCreated, setIsCreated] = useState(false)

  const isValidForm = () => !isLoading && isValidEmail(emailAddress) && !!personalName && !!companyName

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && isValidForm()) {
      void onCreateUser()
    }
  }

  useEffect(() => {
    setEmailAddress(email_address || '')
    setPersonalNme('')
    setCompanyName('')
    setIsCreated(false)
  }, [email_address])

  const onCreateUser = async () => {
    setIsLoading(true)

    const result = await registerUser(emailAddress, personalName, companyName, i18n.language, handleError)
    setIsCreated(result)
    setIsLoading(false)

    // track with mixpanel
    mixpanel.track('Create user account', {
      email: emailAddress,
      'personal name': personalName,
      'company name': companyName,
    })

    return true
  }

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH} onKeyDown={handleKeyDown}>
        <PageHeading>{t('page_heading', { ns: 'register' })}</PageHeading>
        {!isCreated && (
          <VStack w="50%" alignItems="flex-start" spacing={4}>
            <>
              <Text mt={-4} mb={4}>
                {t('description', { ns: 'register' })}
              </Text>
              <FormControl>
                <FormLabel>{t('email_address', { ns: 'register' })}</FormLabel>
                <Input
                  id="email_address"
                  type="text"
                  value={emailAddress}
                  disabled={isLoading}
                  onChange={(e) => setEmailAddress(e.target.value.trim())}
                  autoFocus
                />
              </FormControl>
              <FormControl>
                <FormLabel>{t('personal_name', { ns: 'register' })}</FormLabel>
                <Input
                  id="personal_name"
                  type="text"
                  value={personalName}
                  disabled={isLoading}
                  onChange={(e) => setPersonalNme(e.target.value.trim())}
                />
              </FormControl>
              <FormControl>
                <FormLabel>{t('company_name', { ns: 'register' })}</FormLabel>
                <Input
                  id="company_name"
                  type="text"
                  value={companyName}
                  disabled={isLoading}
                  onChange={(e) => setCompanyName(e.target.value.trim())}
                />
              </FormControl>
              <Button
                colorScheme="primary"
                minW={20}
                disabled={!isValidForm()}
                onClick={() => onCreateUser()}
                isLoading={isLoading}
                loadingText={t('registering', { ns: 'register' })}
                spinnerPlacement="end"
              >
                {t('register', { ns: 'register' })}
              </Button>
            </>
          </VStack>
        )}
        {isCreated && (
          <>
            <VStack alignItems="flex-start" spacing={4}>
              <HStack color="primary.500" fontSize="2xl">
                <CheckCircleIcon />
                <Text fontSize="md" fontWeight="bold">
                  {t('register_success', { ns: 'register' })}
                </Text>
              </HStack>
              <Text>
                {t('post_register_message', { ns: 'register' })}
                <Text color="gray.400">({emailAddress})</Text>
              </Text>
              <Button colorScheme="primary" as={RouterLink} to="/dashboard">
                {t('login', { ns: 'register' })}
              </Button>
            </VStack>
            <FullFeatureSuggestionText />
          </>
        )}
      </Container>
    </>
  )
}

export default Register
